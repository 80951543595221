/* global mobileClient */
import axios from 'axios';
import { createBrowserHistory } from 'history';
import Qs from 'qs';
import { CLIENT_ID, CLIENT_SECRET } from '../constants/config';
import {
  deleteLocalData,
  getAccessToken,
  getParameterByName,
  getRefreshToken,
  includeDateCut,
  isAdmin,
  objToUrlParams,
  removeAxiosHeaders,
  setAccessToken,
  setAxiosHeaders,
  setRefreshToken,
} from '../utils/helper';
import apiEndpoints from './apiEndpoints';

const history = createBrowserHistory({ forceRefresh: true }),
  CancelToken = axios.CancelToken,
  source = CancelToken.source();

let isTokenrefreshing = false,
  retryReqestArray = [],
  call;

function onTokenRefresh(authorisationToken) {
  retryReqestArray.map((cb) => cb(authorisationToken));
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      if (getAccessToken()) {
        if (getRefreshToken()) {
          if (!isTokenrefreshing) {
            removeAxiosHeaders();
            isTokenrefreshing = true;
            const params = {
              client_id: CLIENT_ID,
              client_secret: CLIENT_SECRET,
              grant_type: 'refresh_token',
              refresh_token: getRefreshToken(),
            };
            axios
              .post(apiEndpoints.refreshToken, params)
              .then((response) => {
                setRefreshToken(response.data.refresh_token);
                setAccessToken(response.data.access_token);
                setAxiosHeaders();
                onTokenRefresh(response.data.access_token);
              })
              .catch((error) => {
                removeAxiosHeaders();
                history.push('/');
                deleteLocalData('accessToken');
              });
          }
          return new Promise((resolve) => {
            retryReqestArray.push((token) => {
              const originalRequest = error.config;
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        } else {
          history.push('/');
          removeAxiosHeaders();
          deleteLocalData('accessToken');
        }
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((request) => {
  let requestPlatform = 'web';
  try {
    if (mobileClient) {
      requestPlatform = 'app';
    }
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      requestPlatform = 'm-web';
    }
  }
  request.headers['X-REQUEST-PLATFORM'] = requestPlatform;
  return request;
});

export default {
  user: {
    login: (credentials, headers = null) =>
      axios.post(apiEndpoints.authenticate, credentials, headers).then((res) => res.data),

    logout: (credentials) => axios.post(apiEndpoints.logout, credentials).then((res) => res),

    socialLogin: (credentials) => axios.post(apiEndpoints.socialLogin, credentials).then((res) => res),

    profile: () =>
      axios.get(apiEndpoints.userProfile).then((res) => {
        window.ga &&
          window.ga('set', 'dimension1', {
            id: res.data.id,
          });
        return res;
      }),

    profilePic: (params) =>
      axios
        .post(apiEndpoints.userProfilePic, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data),

    patchProfile: (params) => axios.patch(apiEndpoints.userProfile, params).then((res) => res),

    resetPassword: (params) => axios.post(apiEndpoints.resetPassword, params).then((res) => res.data),

    setPasswordEmail: (id) => axios.post(apiEndpoints.setPasswordEmail + id).then((res) => res.data),

    changeAffiliatePassword: (params) =>
      axios.post(apiEndpoints.changeAffiliatePassword, params).then((res) => res.data),

    tokenLogin: (params) => axios.post(apiEndpoints.tokenLogin, params).then((res) => res.data),

    makeTestUser: (params) => axios.patch(apiEndpoints.makeTestUser, params).then((res) => res),

    bankDetails: (params) => axios.post(apiEndpoints.bankDetails, params).then((res) => res.data),

    patchBankDetails: (params) => axios.patch(apiEndpoints.bankDetails, params).then((res) => res.data),

    affiliateDocument: (params, affiliate) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.affiliateDocument, params, config).then((res) => res.data);
    },

    deleteAffiliateDocument: (id) => axios.delete(apiEndpoints.affiliateDocument + '/' + id).then((res) => res.data),

    getReferrerName: (params) => axios.get(apiEndpoints.getReferrerName, { params }).then((res) => res.data),

    getLeadGenCodes: (url, params = {}, affiliate) => {
      if (!url) {
        url = apiEndpoints.leadGenCode;
      }
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config['headers'] = {
          'VOU-AFF-ID': affiliate,
        };
      }
      config['params'] = params;
      return axios.get(url, config).then((res) => res.data);
    },

    postLeadGenCode: (params, affiliate) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.leadGenCode, params, config).then((res) => res);
    },

    patchLeadGenCode: (id, params) => axios.patch(`${apiEndpoints.leadGenCode}/${id}`, params).then((res) => res),

    getNestedAffiliates: (params = {}, affiliate) => {
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.get(apiEndpoints.nestedAffiliate, { params, headers }).then((res) => res.data);
    },

    patchMicrosite: (id, params) => axios.patch(apiEndpoints.microsite + '/' + id, params).then((res) => res.data),

    postStaffLeave: (params) => axios.post(apiEndpoints.staffLeave, params).then((res) => res.data),

    patchStaffLeave: (id, params) => axios.patch(`${apiEndpoints.staffLeave}/${id}`, params).then((res) => res.data),

    getStaffLeave: (url = apiEndpoints.staffLeave, params) => axios.get(url, { params }).then((res) => res.data),

    deleteStaffLeave: (id) => axios.delete(`${apiEndpoints.staffLeave}/${id}`).then((res) => res.data),

    bulkUpdateAffiliateStaffMap: (params) =>
      axios.post(`${apiEndpoints.bulkUpdateAffiliateStaffMap}`, params).then((res) => res.data),

    bulkUploadStaffLeaves: (params) =>
      axios.post(`${apiEndpoints.bulkUploadStaffLeaves}`, params).then((res) => res.data),

    requestOtp: (params, headers = null) =>
      axios.post(apiEndpoints.requestOtp, params, headers).then((res) => res.data),

    otpSignup: (params) => axios.post(apiEndpoints.otpSignup, params).then((res) => res.data),

    updatePhoneNumber: (params) => axios.post(apiEndpoints.updatePhoneNumber, params).then((res) => res.data),

    verifyPhoneViaOtp: (params) => axios.post(apiEndpoints.verifyPhoneViaOtp, params).then((res) => res.data),

    emailVerification: (params) => axios.post(apiEndpoints.emailVerification, params).then((res) => res.data),

    getMlmTeam: (id, params) => axios.get(`${apiEndpoints.mlmTeam}/${id}`, { params }).then((res) => res.data),

    getAffiliateDownlines: (id, params, url = `${apiEndpoints.affiliateDownlines}/${id}`) =>
      axios.get(url, { params }).then((res) => res.data),

    signupEmail: (params) => axios.post(`${apiEndpoints.signupEmail}`, params).then((res) => res.data),

    getOpenUserDetails: (params) => axios.post(apiEndpoints.openUserDetails, params).then((res) => res.data),

    otpPinReset: (params) => axios.post(apiEndpoints.otpPinReset, params).then((res) => res.data),

    affiliateRelatedAccounts: (params) =>
      axios.get(`${apiEndpoints.affiliateRelatedAccounts}`, { params }).then((res) => res.data),

    cmiInsurerIg: (params) => axios.post(apiEndpoints.cmiInsurerIg, params).then((res) => res.data),
  },
  crm: {
    rolesWithStaff: () => axios.get(apiEndpoints.rolesWithStaff).then((res) => res.data),

    assignRole: (params) => axios.post(apiEndpoints.assignRole, params).then((res) => res.data),

    revokeRole: (params) => axios.post(apiEndpoints.revokeRole, params).then((res) => res.data),

    defaultRole: (params) => axios.post(apiEndpoints.defaultRole, params).then((res) => res.data),

    searchUser: (params) =>
      axios
        .get(apiEndpoints.searchUser, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    affiliates: (url = apiEndpoints.affiliates, params = undefined) =>
      axios
        .get(url, {
          params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    affiliate: (id, params = {}) => {
      if (!Object.keys(params).includes('with_commission_rates')) {
        params.with_commission_rates = true;
      }

      if (!Object.keys(params).includes('with_affiliate_discounts')) {
        params.with_affiliate_discounts = true;
      }

      return axios.get(apiEndpoints.affiliate + '/' + id, { params }).then((res) => res.data);
    },

    getAffiliateUser: (params) => axios.get(apiEndpoints.affiliate, { params }).then((res) => res.data),

    patchAffiliate: (id, params, queryParams = undefined) => {
      let url = `${apiEndpoints.affiliates}/${id}`;
      if (queryParams) {
        url = `${url}${objToUrlParams(queryParams)}`;
      }
      return axios.patch(url, params);
    },

    createUser: (params) => axios.post(apiEndpoints.createUser, params).then((res) => res.data),

    createAffiliate: (params) => axios.post(apiEndpoints.affiliates, params).then((res) => res.data),

    createAffiliateDiscount: (params) => axios.post(apiEndpoints.affliateDiscounts, params),

    patchAffiliateDiscount: (id, params) => axios.patch(apiEndpoints.affliateDiscounts + '/' + id, params),

    createAffiliateCommission: (params) => axios.post(apiEndpoints.affiliateCommission, params),

    patchAffiliateCommission: (id, params) => axios.patch(apiEndpoints.affiliateCommission + '/' + id, params),

    deleteAffiliateCommission: (id) =>
      axios.delete(apiEndpoints.affiliateCommission + '/' + id).then((res) => res.data),

    getLineUsers: (params) => axios.get(apiEndpoints.lineUsers, { params }).then((res) => res.data),

    patchLineUser: (id, params) => axios.patch(apiEndpoints.lineUsers + '/' + id, params),

    forgotPassword: (params) => axios.post(apiEndpoints.forgotPassword, params).then((res) => res.data),

    lineLogin: (params) => axios.post(apiEndpoints.lineLogin, params).then((res) => res.data),

    manualPriceList: (params) => {
      return axios.post(apiEndpoints.manualPriceList, params).then((res) => res.data);
    },
    patchPriceList: (params, id) => {
      return axios.patch(apiEndpoints.manualPriceList + '/' + id, params).then((res) => res.data);
    },

    getSaletrack: (url, params = {}) => {
      if (call) {
        call.cancel('Only one request allowed at a time.');
      }
      call = axios.CancelToken.source();

      params.cancelToken = call.token;

      return axios
        .get(url || apiEndpoints.saleTrack, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },

    postSaletrack: (params) => axios.post(apiEndpoints.saleTrack, params).then((res) => res.data),

    patchSaletrack: (id, params) => axios.patch(apiEndpoints.saleTrack + '/' + id, params).then((res) => res.data),

    getSale: (id, params = {}) => {
      return axios
        .get(apiEndpoints.saleTrack + '/' + id, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },

    fairdeePolicy: (params) => axios.post(apiEndpoints.fairdeePolicy, params).then((res) => res.data),

    getFairdeePolicy: (params) => axios.get(apiEndpoints.fairdeePolicy, { params }).then((res) => res.data),

    patchFairdeePolicy: (id, params) =>
      axios.patch(apiEndpoints.fairdeePolicy + '/' + id, params).then((res) => res.data),

    getAffiliates: (params) => axios.get(apiEndpoints.getAffiliates, { params }).then((res) => res.data),

    generateFormLink: (params) => axios.post(apiEndpoints.generateFormLink, params).then((res) => res.data),

    postInvoice: (params) => axios.post(apiEndpoints.invoice, params).then((res) => res.data),

    getInvoices: (url = null, params = undefined) =>
      axios
        .get(url || apiEndpoints.invoices, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    deleteInvoice: (id) => axios.delete(apiEndpoints.invoice + '/' + id).then((res) => res.data),

    credit: (params) => axios.post(apiEndpoints.credit, params).then((res) => res.data),

    creditTopup: (params) => axios.post(apiEndpoints.creditTopup, params).then((res) => res.data),

    creditTransaction: (params) => axios.post(apiEndpoints.creditTransaction, params).then((res) => res.data),

    deleteCredit: (id, params) =>
      axios.delete(apiEndpoints.credit + '/' + id, { data: params }).then((res) => res.data),

    fairdeeAffiliateEmail: (params) => axios.post(apiEndpoints.fairdeeAffiliateEmail, params).then((res) => res.data),

    fairdeeInsurereEmail: (params) => axios.post(apiEndpoints.fairdeeInsurereEmail, params).then((res) => res.data),

    patchUser: (id, params) => axios.patch(apiEndpoints.patchUser + id, params).then((res) => res.data),

    getAffiliateProfile: (params = {}, token = undefined) => {
      const defaultOptions = {
        headers: {
          Authorization: token ? `Bearer ${token}` : `Bearer ${getAccessToken()}`,
        },
      };
      if (!Object.keys(params).includes('with_commission_rates')) {
        params.with_commission_rates = true;
      }

      if (!Object.keys(params).includes('with_affiliate_discounts')) {
        params.with_affiliate_discounts = true;
      }

      return axios.get(apiEndpoints.affiliateProfile, { params, ...defaultOptions }).then((res) => {
        window.ga &&
          window.ga('set', 'dimension1', {
            id: res.data.message.user.id,
            affiliate_id: res.data.message.id,
          });
        return res.data;
      });
    },
    fairdeeSaleDashboard: (params = {}, url) =>
      axios
        .get(url || apiEndpoints.fairdeeSaleDashboard, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    affiliateIncentive: (params) => axios.post(apiEndpoints.affiliateIncentive, params).then((res) => res.data),

    patchAffiliateIncentive: (id, params) =>
      axios.patch(apiEndpoints.affiliateIncentive + `/${id}`, params).then((res) => res.data),

    deleteAffiliateIncentive: (id) => axios.delete(apiEndpoints.affiliateIncentive + `/${id}`).then((res) => res.data),

    fairdeeSaleIncentive: (params) => axios.post(apiEndpoints.fairdeeSaleIncentive, params).then((res) => res.data),

    patchFairdeeSaleIncentive: (id, params) =>
      axios.patch(apiEndpoints.fairdeeSaleIncentive + `/${id}`, params).then((res) => res.data),

    deleteFairdeeSaleIncentive: (id) =>
      axios.delete(apiEndpoints.fairdeeSaleIncentive + `/${id}`).then((res) => res.data),

    getReferralIncentives: (url = undefined, params = {}) =>
      axios
        .get(apiEndpoints.referralIncentives, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getAffiliateReferrals: (url = apiEndpoints.affiliateReferrals, params = {}) => {
      const headers = {};
      if (params.affiliate) {
        headers['VOU-AFF-ID'] = params.affiliate;
        delete params.affiliate;
      }
      return axios
        .get(url, {
          params,
          headers,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },

    getReferralIncentivesMonthly: (url = apiEndpoints.referralIncentiveMonthly, params = {}) =>
      axios.get(url, { params }).then((res) => res.data),

    getSaleEconomics: (params = {}) =>
      axios
        .get(apiEndpoints.saleEconomics, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    affiliateSignup: (params) =>
      axios
        .post(apiEndpoints.affiliateSignup, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res),

    signupV2: (params) => axios.post(apiEndpoints.signupV2, params).then((res) => res.data),

    getTasks: (params = {}, endpoint = apiEndpoints.tasks) =>
      axios
        .get(endpoint, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getTask: (id) => axios.get(apiEndpoints.tasks + '/' + id).then((res) => res.data),

    createTask: (params) => axios.post(apiEndpoints.tasks, params).then((res) => res.data),

    bulkTaskCreate: (params) => axios.post(apiEndpoints.bulkTaskCreate, params).then((res) => res.data),

    deleteTask: (id) => axios.delete(`${apiEndpoints.tasks}/${id}`).then((res) => res.data),

    patchtask: (id, params) => axios.patch(`${apiEndpoints.tasks}/${id}`, params).then((res) => res.data),

    rolesWithStaff: () => axios.get(apiEndpoints.rolesWithStaff).then((res) => res.data),

    taskSummary: () => axios.get(apiEndpoints.taskSummary).then((res) => res.data),

    taskBulkUpdate: (params) => axios.post(apiEndpoints.taskBulkUpdate, params).then((res) => res.data),

    affiliateClassifiaction: (id, params) =>
      axios.patch(`${apiEndpoints.affiliateClassifiaction}${id}`, params).then((res) => res.data),

    createInteraction: (params) => axios.post(apiEndpoints.interactions, params).then((res) => res.data),

    patchInteraction: (id, params = {}) =>
      axios.patch(`${apiEndpoints.interactions}/${id}`, params).then((res) => res.data),

    getInteractions: (params = {}) => axios.get(apiEndpoints.interactions, { params }).then((res) => res.data),

    createTags: (id, params) => axios.patch(`${apiEndpoints.tags}/${id}`, params).then((res) => res.data),

    policyDocument: (params) =>
      axios
        .post(apiEndpoints.policyDocument, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res),

    deleteDocument: (id) => axios.delete(`${apiEndpoints.policyDocument}/${id}`).then((res) => res.data),

    affiliateSaleSummary: (params = {}) =>
      axios.get(apiEndpoints.affiliateSaleSummary, { params }).then((res) => res.data),

    staffForTasks: (params = {}) => axios.get(apiEndpoints.staffForTasks, { params }).then((res) => res.data),

    createStaffForTasks: (params) => axios.post(apiEndpoints.staffForTasks, params).then((res) => res.data),

    removeStaff: (id) => axios.delete(apiEndpoints.staffForTasks + '/' + id).then((res) => res.data),

    quotationShareLink: (params) => axios.post(apiEndpoints.quotationShareLink, params).then((res) => res.data),

    getTags: () => axios.get(apiEndpoints.tagsGet).then((res) => res.data),

    payment: (params = {}) => axios.post(apiEndpoints.payment, params).then((res) => res.data),

    patchPayment: (id, params = {}) => axios.patch(apiEndpoints.payment + '/' + id, params).then((res) => res.data),

    generateShortUrl: (params) => axios.post(apiEndpoints.shortUrl, params).then((res) => res.data),

    getMonitorySummary: (params) => {
      let config = {};
      if (params.affiliate_id) {
        config['headers'] = {
          'VOU-AFF-ID': params.affiliate_id,
        };
        delete params.affiliate_id;
      }
      config['params'] = params;
      return axios.get(apiEndpoints.monitorySummary, config).then((res) => res.data);
    },

    getAffiliateGroupedCommission: (params) =>
      axios.get(apiEndpoints.affiliateGroupedCommission, { params }).then((res) => res.data),

    getAffiliateNetPayable: (params) => axios.get(apiEndpoints.affiliateNetPayable, { params }).then((res) => res.data),

    postaffiliateGroupedCombined: (params) => {
      let endpoint = apiEndpoints.affiliateGroupedCombined;
      let config = {};
      if (params.resp_format === 'xlsx') {
        endpoint = `${endpoint}?resp_format=xlsx`;
        delete params.resp_format;
        config = { responseType: 'blob' };
      }
      return axios.post(endpoint, params, config).then((res) => res.data);
    },

    postEndorsementCombinedInvoice: (params) => {
      let endpoint = apiEndpoints.endorsementCombinedInvoice,
        config = {};
      if (params.resp_format === 'xlsx') {
        endpoint = `${endpoint}?resp_format=xlsx`;
        delete params.resp_format;
        config = { responseType: 'blob' };
      }
      return axios.post(endpoint, params, config).then((res) => res.data);
    },

    getAffiliateGroupedIncentive: (params) =>
      axios.get(apiEndpoints.affiliateGroupedIncentive, { params }).then((res) => res.data),

    postFairdeePolicyPurchase: (params) =>
      axios.post(apiEndpoints.policyPurchase, params).then((res) => {
        return { ...res.data, status: res.status };
      }),

    getInstalmentInvoices: (url = apiEndpoints.instalmentInvoices, params, vendor) => {
      let config = {
        paramsSerializer: function (params) {
          return Qs.stringify(params, { indices: false });
        },
        params: params,
      };
      if (vendor) {
        config.headers = {
          'VOU-VENDOR-NAME': vendor,
        };
      }

      return axios.get(url, config).then((res) => res.data);
    },
    getInstalmentIPs: (url = apiEndpoints.instalmentIPs, params, vendor) => {
      let config = {
        paramsSerializer: function (params) {
          return Qs.stringify(params, { indices: false });
        },
        params: params,
      };
      if (vendor) {
        config.headers = {
          'VOU-VENDOR-NAME': vendor,
        };
      }
      return axios.get(url, config).then((res) => res.data);
    },
    recreateCreditInvoice: (id) => axios.post(`${apiEndpoints.recreateCreditInvoice}/${id}`).then((res) => res.data),

    createCmiCombinedInvoice: (params) =>
      axios.post(apiEndpoints.createCmiCombinedInvoice, params).then((res) => res.data),

    postSubagentCredits: (params) => axios.post(apiEndpoints.subagentCredits, params).then((res) => res.data),

    patchSubagentCredits: (id, params) =>
      axios.patch(`${apiEndpoints.subagentCredits}/${id}`, params).then((res) => res.data),

    patchSubagentCommssison: (id, params) =>
      axios.patch(`${apiEndpoints.subagentCommission}/${id}`, params).then((res) => res.data),

    postSubagentCommission: (params) => axios.post(apiEndpoints.subagentCommission, params).then((res) => res.data),

    getSubagents: (url = apiEndpoints.subagents, params) => axios.get(url, { params }).then((res) => res.data),

    postSubagentReconcileDocument: (params) =>
      axios.post(apiEndpoints.subagentReconcile, params).then((res) => res.data),

    subagentTopup: (params) => axios.post(apiEndpoints.subagentReconcile, params).then((res) => res.data),

    syncOicData: (id) => axios.patch(`${apiEndpoints.syncOicData}/${id}`).then((res) => res.data),

    resetOtpAttempt: (id) => axios.patch(`${apiEndpoints.resetOtpAttempt}/${id}`).then((res) => res.data),

    getStaffTiming: (url, params) => axios.get(url || apiEndpoints.staffTiming, { params }).then((res) => res.data),

    deleteStaffTiming: (id) => axios.delete(`${apiEndpoints.staffTiming}/${id}`).then((res) => res.data),

    postStaffTeam: (params) => axios.post(apiEndpoints.staffTeam, params).then((res) => res.data),

    getStaffTeam: (url, params) => axios.get(url || apiEndpoints.staffTeam, { params }).then((res) => res.data),

    postStaffTiming: (params) => axios.post(apiEndpoints.staffTiming, params).then((res) => res.data),

    patchStaffTiming: (id, params) => axios.patch(`${apiEndpoints.staffTiming}/${id}`, params).then((res) => res.data),

    postStaffTeamMap: (params) => axios.post(apiEndpoints.staffTeamMapping, params).then((res) => res.data),

    removeTeamFromStaff: (id, params) =>
      axios.delete(`${apiEndpoints.staffTeamMapping}/${id}`, params).then((res) => res.data),

    deleteStaffTeamMap: (params) =>
      axios.delete(`${apiEndpoints.staffTeamMapping}`, { params }).then((res) => res.data),

    getAffiliateTags: (affiliateId) =>
      axios.get(`${apiEndpoints.affiliateTags}/${affiliateId}`).then((res) => res.data),

    deleteAffiliateTags: (affiliateId, name) =>
      axios.delete(`${apiEndpoints.affiliateTags}/${affiliateId}`, { data: { name } }).then((res) => res.data),

    postAffiliateTags: (affiliateId, name) =>
      axios.post(`${apiEndpoints.affiliateTags}/${affiliateId}`, { name }).then((res) => res.data),

    massTaskReassignment: (params) => axios.post(apiEndpoints.massTaskReassignment, params).then((res) => res.data),

    getSupportStaff: (params) => {
      return axios.get(apiEndpoints.supportStaff, { params }).then((res) => res.data);
    },

    getCredits: (params) => axios.get(apiEndpoints.crmCredit, { params }).then((res) => res.data),
  },

  utils: {
    getConversationImageDescription: (params) => {
      return axios.get(apiEndpoints.getConversationImageDescription, { params }).then((res) => res.data);
    },
    updateConversationImageDescription: (params) => {
      return axios.patch(`${apiEndpoints.getConversationImageDescription}`, params).then((res) => res.data);
    },
    processChatwootConversation: (params) => {
      return axios.post(apiEndpoints.processChatwootConversation, params);
    },
    fetchInstantPolicyPdf: (params) => {
      return axios.post(apiEndpoints.fetchInstantPolicyPdf, params);
    },

    compulsoryInsurance: (params) => {
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios.post(apiEndpoints.compulsoryInsurance, params, config);
    },

    generateSaleDocuments: (params = {}) =>
      axios.post(apiEndpoints.generateSaleDocuments, params).then((res) => res.data),

    patchCompulsoryInsurance: (id, params = {}) => {
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios.patch(apiEndpoints.compulsoryInsurance + '/' + id, params, config);
    },

    cmiPolicyUpload: (id, params) => {
      let headers = {
        'Content-Type': 'multipart/form-data',
      };
      return axios
        .patch(apiEndpoints.compulsoryInsurance + '/' + id, params, {
          headers,
        })
        .then((res) => res.data);
    },

    buyCompulsoryInsurance: (id, params) => {
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(`${apiEndpoints.compulsoryInsurance}/buy/${id}`, params, config);
    },

    compulsoryInsuranceList: (url = apiEndpoints.compulsoryInsuranceList, params = {}, affiliate) => {
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios.get(url, {
        params,
        paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        headers,
      });
    },

    getCompulsoryInsurance: (id, params) => axios.get(`${apiEndpoints.compulsoryInsurance}/${id}`, { params }),

    getCompulsoryInsuranceForm: (params, affiliate) =>
      axios
        .get(`${apiEndpoints.compulsoryInsurance}`, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
          headers: {
            'VOU-AFF-ID': affiliate,
          },
        })
        .then((res) => res.data),

    quotationQuery: (params) => {
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.quotationQuery, params, config).then((res) => res.data);
    },

    getQuotationQuery: (id, params) => {
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.get(apiEndpoints.quotationQuery + '/' + id, { params }, config).then((res) => res.data);
    },

    getQuotationQueryWithParams: (url = null, params = undefined) => {
      includeDateCut(url || '', params, 'leadsReport');
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios
        .get(
          url || apiEndpoints.quotationQuery,
          {
            params,
            paramsSerializer: function (params) {
              return Qs.stringify(params, { indices: false });
            },
          },
          config
        )
        .then((res) => res.data);
    },

    getNonMotoQuotesListing: (url = apiEndpoints.proxyRoute, params = {}, affiliate) => {
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios.get(url, {
        params,
        paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        headers,
      });
    },

    getNonMotorQuote: (url = apiEndpoints.proxyRoute, params = {}, affiliate) => {
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios.get(url + '/' + params.id, {
        params,
        paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        headers,
      });
    },

    getFormDetails: (url = apiEndpoints.proxyRoute, params = {}, affiliate) => {
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios
        .get(url + '/' + params.schema_id, {
          params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
          headers,
        })
        .then((res) => res.data);
    },

    updateFormDetails: (url = apiEndpoints.proxyRoute, params = {}, affiliate) => {
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers = {
          'VOU-AFF-ID': affiliate,
        };
      }

      return axios.patch(url, params).then((res) => res.data);
    },

    getQoalaOrder: (id) => {
      return axios.get(`${apiEndpoints.qoalaOrder}/${id}`).then((res) => res.data.message);
    },

    patchQoalaOrder: (id, params) => {
      return axios.patch(`${apiEndpoints.qoalaOrder}/${id}`, params).then((res) => res.data);
    },

    patchQuotationQuery: (id, params) => {
      let affiliate = getParameterByName('affiliate');
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.patch(apiEndpoints.quotationQuery + '/' + id, params, config).then((res) => res.data);
    },

    generatePdf: (params) =>
      axios
        .get(apiEndpoints.generatePdf, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    initialLead: (params) => axios.post(apiEndpoints.initialLead, params).then((res) => res.data),

    initialLeadPatch: (params, id) => axios.patch(apiEndpoints.initialLead + '/' + id, params).then((res) => res.data),

    fairdeeQuotation: (params, affiliate) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.fairdeeQuotation, params, config).then((res) => res.data);
    },

    patchFairdeeQuotation: (id, params, affiliate = undefined) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.patch(apiEndpoints.fairdeeQuotation + '/' + id, params, config).then((res) => res.data);
    },

    getFairdeeQuotation: (id, params = {}) =>
      axios.get(apiEndpoints.fairdeeQuotation + '/' + id, { params }).then((res) => res.data),

    getFairdeeQuotations: (url = apiEndpoints.fairdeeQuotation, params = {}) =>
      axios
        .get(url, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    getFairdeeQuotationReport: (url = apiEndpoints.fairdeeQuotationReport, params = {}) => {
      includeDateCut(url, params);
      return axios
        .get(url, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data);
    },

    getFairdeeQuotationReportGQL: (url = apiEndpoints.fairdeeQuotationReportGQL, params = {}) => {
      includeDateCut(url, params);
      return axios
        .post(
          url,
          {
            query: params.query,
          },
          {
            params: { limit: params.limit, offset: params.offset },
          }
        )
        .then((res) => res.data);
    },

    quotationDocumentUpload: (params) => {
      let affiliate = getParameterByName('affiliate');
      let headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      return axios
        .post(apiEndpoints.quotationDocumentUpload, params, {
          headers,
        })
        .then((res) => res.data);
    },

    quotationDocumentUploadViaUrl: (params) => {
      console.log(params);
      return axios.post(apiEndpoints.quotationDocumentUpload, params).then((res) => res.data);
    },

    deleteDocument: (id) => axios.delete(apiEndpoints.quotationDocumentUpload + '/' + id).then((res) => res.data),

    patchQuotationDocument: (id, params) =>
      axios.patch(apiEndpoints.quotationDocumentUpload + '/' + id, params).then((res) => res.data),

    quotationReport: (url = apiEndpoints.quotationReport, params) => axios.get(url, { params }).then((res) => res.data),

    compulsaryInsurance: (url = apiEndpoints.compulsaryInsurance, params) =>
      axios.get(url, { params }).then((res) => res.data),

    addRemark: (params) => axios.post(apiEndpoints.remark, params).then((res) => res.data),

    patchRemark: (id, params) => axios.patch(apiEndpoints.remark + '/' + id, params).then((res) => res.data),

    deleteRemark: (id) => axios.delete(apiEndpoints.remark + '/' + id).then((res) => res.data),

    getRemarks: (url = null, params) => axios.get(url || apiEndpoints.remark, { params }).then((res) => res.data),

    getTokenisedQuotation: (id, params = {}) =>
      axios.get(apiEndpoints.quotationWithToken + '/' + id, { params }).then((res) => res.data),

    patchTokenisedQuotation: (id, params, t) =>
      axios.patch(apiEndpoints.quotationWithToken + '/' + id + '?t=' + t, params).then((res) => res.data),

    postTokenisedQuotation: (id, t, params) =>
      axios.post(apiEndpoints.quotationWithToken + '/' + id + '?t=' + t, params).then((res) => res.data),

    getTokenisedQuotationQuery: (id, params = {}) =>
      axios.get(apiEndpoints.quotationQueryWithToken + id, { params }).then((res) => res.data),

    patchTokenisedQuotationQuery: (id, params, t) =>
      axios.patch(apiEndpoints.quotationQueryWithToken + id + '?t=' + t, params).then((res) => res.data),

    getDocuments: (id, params) => axios.get(apiEndpoints.documents + '/' + id, { params }).then((res) => res.data),

    postDocument: (params, token) =>
      axios
        .post(apiEndpoints.documents + '?t=' + token, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data),

    deleteTokenisedDocument: (id, token) =>
      axios.delete(apiEndpoints.documents + '/' + id + '?t=' + token).then((res) => res.data),

    postCarWithToken: (id, params, token) =>
      axios.post(apiEndpoints.carWithToken + id + '?t=' + token, params).then((res) => res.data),

    getCarWithToken: (id, token) => axios.get(apiEndpoints.carWithToken + id + '?t=' + token).then((res) => res.data),

    patchCarWithToken: (params, id, token) =>
      axios.patch(apiEndpoints.carWithToken + id + '?t=' + token, params).then((res) => res.data),

    postDriverWithToken: (id, params, token) =>
      axios.post(apiEndpoints.driverWithToken + id + '?t=' + token, params).then((res) => res.data),

    getDriverWithToken: (id, token) =>
      axios.get(apiEndpoints.driverWithToken + id + '?t=' + token).then((res) => res.data),

    patchDriverWithToken: (id, params, token) =>
      axios.patch(apiEndpoints.driverWithToken + id + '?t=' + token, params).then((res) => res.data),

    getCompulsorySaleEconomics: (url = apiEndpoints.compulsorySaleEconomics, params = {}) =>
      axios
        .get(apiEndpoints.compulsorySaleEconomics, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    postCoaDocument: (params) =>
      axios
        .post(apiEndpoints.coaDocument, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data),

    getCoaDocument: (id) => axios.get(apiEndpoints.coaDocument + '/' + id).then((res) => res.data),

    deleteCoaDocument: (id) => axios.delete(apiEndpoints.coaDocument + '/' + id).then((res) => res.data),

    getInstalmentTypes: (id) => axios.get(apiEndpoints.instalmentTypes + '/' + id).then((res) => res.data),

    generateContract: (params) =>
      axios({
        method: 'post',
        url: apiEndpoints.generateContract,
        data: params,
        responseType: 'blob',
      }).then((res) => res.data),

    postRenewalQuote: (params) => {
      return axios.post(apiEndpoints.renewQuote, params).then((res) => res.data);
    },

    covidDocumentUpload: (params) => {
      let affiliate = getParameterByName('affiliate');
      let headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      return axios
        .post(apiEndpoints.covidDocument, params, {
          headers,
        })
        .then((res) => res.data);
    },

    dengueDocumentUpload: (params) => {
      let affiliate = getParameterByName('affiliate');
      let headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      return axios
        .post(apiEndpoints.dengueDocument, params, {
          headers,
        })
        .then((res) => res.data);
    },

    paDocumentUpload: (params) => {
      let affiliate = getParameterByName('affiliate');
      let headers = {
        'Content-Type': 'multipart/form-data',
      };
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      return axios
        .post(apiEndpoints.paDocument, params, {
          headers,
        })
        .then((res) => res.data);
    },

    patchCovidDocument: (id, params) => {
      return axios.patch(`${apiEndpoints.covidDocument}/${id}`, params).then((res) => res.data);
    },

    patchDengueDocument: (id, params) => {
      return axios.patch(`${apiEndpoints.dengueDocument}/${id}`, params).then((res) => res.data);
    },

    deleteDengueDocument: (id) => axios.delete(apiEndpoints.dengueDocument + '/' + id).then((res) => res.data),

    patchPADocument: (id, params) => {
      return axios.patch(`${apiEndpoints.paDocument}/${id}`, params).then((res) => res.data);
    },

    deletePADocument: (id) => axios.delete(apiEndpoints.paDocument + '/' + id).then((res) => res.data),

    deleteCovidDocument: (id) => axios.delete(apiEndpoints.covidDocument + '/' + id).then((res) => res.data),

    postCovidForm: (params, affiliate) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.covidForm, params, config).then((res) => res.data);
    },

    renewCovidForm: (params, affiliate) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.renewCovidForm, params, config).then((res) => res.data);
    },

    patchCovidForm: (id, params, affiliate = undefined) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.patch(apiEndpoints.covidForm + '/' + id, params, config).then((res) => res.data);
    },

    getCovidForm: (id, params = {}) => axios.get(apiEndpoints.covidForm + '/' + id, { params }).then((res) => res.data),

    getCovidForms: (url, params) =>
      axios
        .get(url || apiEndpoints.covidForm, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    generateCovidSaleDocuments: (params = {}) =>
      axios.post(apiEndpoints.covidSaleDocuments, params).then((res) => res.data),

    postDengueForm: (params, affiliate) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.dengueForm, params, config).then((res) => res.data);
    },

    patchDengueForm: (id, params, affiliate = undefined) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.patch(apiEndpoints.dengueForm + '/' + id, params, config).then((res) => res.data);
    },

    getDengueForm: (id, params = {}) =>
      axios.get(apiEndpoints.dengueForm + '/' + id, { params }).then((res) => res.data),

    getDengueForms: (url, params) =>
      axios
        .get(url || apiEndpoints.dengueForm, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    generateDengueSaleDocuments: (params = {}) =>
      axios.post(apiEndpoints.dengueSaleDocuments, params).then((res) => res.data),

    postPAForm: (params, affiliate) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.post(apiEndpoints.paForm, params, config).then((res) => res.data);
    },

    patchPAForm: (id, params, affiliate = undefined) => {
      let config = {};
      if (affiliate && !isNaN(affiliate)) {
        config.headers = {
          'VOU-AFF-ID': affiliate,
        };
      }
      return axios.patch(apiEndpoints.paForm + '/' + id, params, config).then((res) => res.data);
    },

    getPAForm: (id, params = {}) => axios.get(apiEndpoints.paForm + '/' + id, { params }).then((res) => res.data),

    getPAForms: (url, params) =>
      axios
        .get(url || apiEndpoints.paForm, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    generatePASaleDocuments: (params = {}) => axios.post(apiEndpoints.paSaleDocuments, params).then((res) => res.data),

    uploadMTICovidPolicies: (params) => {
      let headers = {
        'Content-Type': 'multipart/form-data',
      };

      return axios
        .post(apiEndpoints.uploadMTICovidPolicies, params, {
          headers,
        })
        .then((res) => res.data);
    },

    uploadPolicyTrackingIds: (params) => {
      let headers = {
        'Content-Type': 'multipart/form-data',
      };

      return axios
        .post(apiEndpoints.uploadVMITrackingIds, params, {
          headers,
        })
        .then((res) => res.data);
    },

    uploadVIBCovidPolicies: (params) => {
      let headers = {
        'Content-Type': 'multipart/form-data',
      };

      return axios
        .post(apiEndpoints.uploadVIBCovidPolicies, params, {
          headers,
        })
        .then((res) => res.data);
    },

    duplicateQuotation: (params) => axios.post(apiEndpoints.duplicateQuotation, params).then((res) => res.data),

    retryCMIPolicies: () => axios.post(apiEndpoints.retryCMIPolicies).then((res) => res.data),

    postCovidFormRemark: (params) => axios.post(apiEndpoints.covidFormRemark, params).then((res) => res.data),

    patchCovidFormRemark: (id, params) =>
      axios.patch(`${apiEndpoints.covidFormRemark}/${id}`, params).then((res) => res.data),

    getCovidFormRemarks: (id) => axios.get(`${apiEndpoints.covidFormRemark}/${id}`).then((res) => res.data),

    deleteCovidFormRemark: (id) => axios.delete(`${apiEndpoints.covidFormRemark}/${id}`).then((res) => res.data),

    deleteCovidFormRemark: (id) => axios.delete(`${apiEndpoints.covidFormRemark}/${id}`).then((res) => res.data),

    priceListInstallmentType: (params) =>
      axios
        .get(apiEndpoints.priceListInstallmentType, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    getTokenisedPriceListInstallmentType: (params) =>
      axios
        .get(apiEndpoints.tokenisedPriceListInstallmentType, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    getHistoricalData: (params) =>
      axios
        .get(apiEndpoints.entityHistoricalData, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    uploadFile: (params) => {
      let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        url = apiEndpoints.uploadFile;

      return axios.post(url, params, config).then((res) => res.data);
    },

    uploadOptions: () => axios.get(apiEndpoints.uploadOptions).then((res) => res.data),

    downloadSumInsured: (params) => axios.get(apiEndpoints.downloadSumInsured, { params }).then((res) => res.data),

    checkMultipleQuotation: (params) =>
      axios
        .get(apiEndpoints.checkMultipleQuotation, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),
    quotationRemark: (params) => axios.post(apiEndpoints.quotationRemark, params).then((res) => res.data),

    patchQuotationRemark: (id, params) =>
      axios.patch(`${apiEndpoints.quotationRemark}/${id}`, params).then((res) => res.data),

    getQuotationQueryRemark: (id, params) =>
      axios
        .get(`${apiEndpoints.quotationRemark}`, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    deleteQuotationRemark: (id) => axios.delete(apiEndpoints.quotationRemark + '/' + id).then((res) => res.data),

    uploadInsurerRequestDocument: (params) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      return axios.post(apiEndpoints.uploadInsurerRequest, params, config).then((res) => res.data);
    },

    deleteInsurerRequestDocument: (id) =>
      axios.delete(`${apiEndpoints.uploadInsurerRequest}/${id}`).then((res) => res.data),

    sendInsurerRequestEmail: (params) => axios.post(apiEndpoints.sendInsurerEmail, params).then((res) => res.data),

    removeUploadedInsurerDocument: (id) =>
      axios.delete(`${apiEndpoints.removeUploadedDocument}/${id}`).then((res) => res.data),

    combinedCreditAccountingActions: (params) =>
      axios
        .get(apiEndpoints.combinedCreditAccountingActions, {
          params: params,
        })
        .then((res) => res.data),

    uploadGenericDocument: (params = {}) =>
      axios.post(apiEndpoints.uploadGenericDocument, params).then((res) => res.data),

    deleteGenericDocument: (id) => axios.delete(`${apiEndpoints.uploadGenericDocument}/${id}`).then((res) => res.data),

    vmiReportIG: (params) => axios.post(`${apiEndpoints.createTask}`, params).then((res) => res.data),

    sendCreditCombinedInvoice: (id, params = {}) =>
      axios.get(`${apiEndpoints.sendCreditCombinedInvoice}/${id}`, { params }).then((res) => res.data),

    generateCommisionReport: (params) => axios.post(`${apiEndpoints.createTask}`, params).then((res) => res.data),

    getTaskReports: (endpoint = apiEndpoints.taskResults, params) => {
      return axios.get(endpoint, {
        params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, { indices: false });
        },
      });
    },

    changeReferrer: (id, params) => axios.post(`${apiEndpoints.changeReferrer}/${id}`, params).then((res) => res.data),

    uploadOcr: (params) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      return axios.post(apiEndpoints.ocr, params, config).then((res) => res.data);
    },
    getOcr: (id) => {
      return axios.get(`${apiEndpoints.ocr}/${id}`).then((res) => res.data);
    },
    deleteOcr: (id) => {
      return axios.delete(`${apiEndpoints.ocr}/${id}`).then((res) => res.data);
    },
    uploadPromotionBanner: (params) => axios.post(apiEndpoints.promotionBanner, params).then((res) => res.data),

    getPromotionBanner: (endpoint = apiEndpoints.getPromotionBanner, params) => {
      return axios.get(endpoint, {
        params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, { indices: false });
        },
      });
    },

    changeStatusManualLead: (params) => axios.post(apiEndpoints.changeStatusManualLead, params).then((res) => res.data),
    addManualLeadRemark: (params) => axios.post(apiEndpoints.manualLeadRemark, params).then((res) => res.data),

    sendInsurerRequestEmailManual: (id) => axios.get(apiEndpoints.sendManualQuoteToInsurer, id).then((res) => res.data),

    uploadDocumentManual: (params) => axios.post(apiEndpoints.uploadDocumentManual, params).then((res) => res.data),

    vehicleNumberSearch: (params) => axios.get(apiEndpoints.vehicleNumberSearch, { params }).then((res) => res.data),

    postCalendarEvents: (params) => {
      return axios.post(apiEndpoints.eventCalendar, params).then((res) => res.data);
    },

    getCalendarEvents: (url = apiEndpoints.eventCalendar, params = {}) => {
      return axios
        .get(url, {
          params: params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data);
    },

    deleteCalendarEvent: (id) => {
      return axios.delete(`${apiEndpoints.eventCalendar}/${id}/`).then((res) => res.data);
    },

    patchCalendarEvent: (id, params) => {
      return axios.patch(`${apiEndpoints.eventCalendar}/${id}/`, params).then((res) => res.data);
    },

    getBanners: (params, endpoint = apiEndpoints.getBanners) => {
      return axios
        .get(endpoint, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },

    deleteBanner: (id) => {
      return axios.delete(`${apiEndpoints.getBanners}/${id}`).then((res) => res.data);
    },

    getProxyDetails: (id, params) => {
      if (id) {
        return axios
          .get(`${apiEndpoints.proxyRoute}/${id}`, {
            params,
            paramsSerializer: function (params) {
              return Qs.stringify(params, { indices: false });
            },
          })
          .then((res) => res.data);
      }
      return axios
        .get(apiEndpoints.proxyRoute, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },

    postproxyDetails: (params) => axios.post(apiEndpoints.proxyRoute, params).then((res) => res.data),

    patchProxyDetails: (id, params) => {
      return axios.patch(`${apiEndpoints.proxyRoute}/${id}`, params).then((res) => res.data);
    },

    getFormIOByCode: (params) =>
      axios.get(`${apiEndpoints.formIOGetForm}/${params.code}/${params.version}`).then((res) => res.data),

    fileStorage: (params) => {
      let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        url = apiEndpoints.fileStorage;

      return axios.post(url, params, config).then((res) => res.data);
    },

    getFileStorage: (id) => axios.get(`${apiEndpoints.fileStorage}/${id}`).then((res) => res.data),
    updateSelfServe: (params) => axios.post(apiEndpoints.updateSelfServe, params).then((res) => res.data),

    verifySelfInspectionPhone: (id, params) =>
      axios
        .get(`${apiEndpoints.verifyClientPhone}/${id}`, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    refreshFalconStatus: (id) => axios.get(`${apiEndpoints.falconStatus}/${id}`).then((res) => res.data),
  },
  masterData: {
    getVehicleMake: (params = {}) => {
      let config = {
        headers: { Authorization: '' },
        params,
      };
      return axios.get(apiEndpoints.vehicleMake, config).then((res) => res.data);
    },

    getVehicleModels: (id, params = {}) => {
      let config = {
        headers: { Authorization: '' },
        params,
      };
      return axios.get(apiEndpoints.vehicleModels + id, config).then((res) => res.data);
    },

    getVehicleCapacity: (id) => {
      let config = {
        headers: { Authorization: '' },
      };
      return axios.get(apiEndpoints.vehicleCc + id, config).then((res) => res.data);
    },

    getVehicleDescription: (id, params = {}) =>
      axios.get(apiEndpoints.vehicleDescription + id, { params }).then((res) => res.data),

    getVehicleDesc: (id, params = {}) => axios.get(apiEndpoints.vehicleDesc + id, { params }).then((res) => res.data),

    getPriceList: (params, url = apiEndpoints.getPriceList) => {
      let affiliate = getParameterByName('affiliate'),
        isInstalment = getParameterByName('instalment') === 'true' && isAdmin();
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      if (isInstalment) {
        params.insurer_with_instalment = true;
      }

      return axios
        .get(url, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
          headers,
        })
        .then((res) => res.data);
    },

    getCheapestPrice: (params) => {
      let affiliate = getParameterByName('affiliate'),
        isInstalment = getParameterByName('instalment') === 'true' && isAdmin();
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      if (isInstalment) {
        params.insurer_with_instalment = true;
      }

      return axios
        .get(apiEndpoints.getCheapestPrice, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
          headers,
        })
        .then((res) => res.data);
    },

    getSelectedDeletedPrices: (params) => {
      let affiliate = getParameterByName('affiliate'),
        isInstalment = getParameterByName('instalment') === 'true' && isAdmin();
      let headers = {};
      if (affiliate && !isNaN(affiliate)) {
        headers['VOU-AFF-ID'] = affiliate;
      }
      if (isInstalment) {
        params.insurer_with_instalment = true;
      }

      return axios
        .get(apiEndpoints.selectedDeletedPrice, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
          headers,
        })
        .then((res) => res.data);
    },

    getPriceListWithId: (id, params, affiliateId) => {
      let headers = {};
      if (affiliateId) {
        headers['VOU-AFF-ID'] = affiliateId;
      }
      return axios
        .get(apiEndpoints.getPriceList + `/${id}`, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
          headers,
        })
        .then((res) => res.data);
    },

    cmiPriceList: (params, affiliateId) => {
      let headers = {};
      if (affiliateId) {
        headers['VOU-AFF-ID'] = affiliateId;
      }
      return axios
        .get(apiEndpoints.cmiPriceList, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
          headers,
        })
        .then((res) => res.data);
    },

    getInsurers: (params = {}) => {
      if (getParameterByName('instalment') === 'true' && isAdmin()) {
        params.is_instalment_supported = true;
      }
      return axios.get(apiEndpoints.insurers, { params }).then((res) => res.data);
    },

    getInsurersEmailsNonMotor: (params = {}) => {
      return axios.get(apiEndpoints.insurersEmailNonMotor, { params }).then((res) => res.data);
    },

    patchInsurers: (id, params) => {
      return axios.patch(`${apiEndpoints.insurers}/${id}`, params).then((res) => res.data);
    },

    patchInsurersNonMotor: (id, params) => {
      return axios.patch(`${apiEndpoints.insurersEmailNonMotor}/${id}`, params).then((res) => res.data);
    },

    getCorporates: () => axios.get(apiEndpoints.corporates).then((res) => res.data),

    packageDetails: (id) => axios.get(apiEndpoints.packageDetails + id).then((res) => res.data),

    getCarTypePackageList: (id) => axios.get(apiEndpoints.carTypePriceList + id).then((res) => res.data),

    sumInsured: (params) =>
      axios
        .get(apiEndpoints.sumInsured, {
          params,
          paramsSerializer: (params) => {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getCarMakeModelData: (id) => axios.get(apiEndpoints.getCarData + id).then((res) => res.data),

    getProvinces: () => axios.get(apiEndpoints.provinces).then((res) => res.data),

    brokerBankAccounts: () => axios.get(apiEndpoints.brokerBankAccounts).then((res) => res.data),

    deleteInsurerPriceList: (params) => axios.patch(apiEndpoints.modifyPriceList, params).then((res) => res.data),

    postInsurerPriceList: (params) => {
      let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        url = apiEndpoints.modifyPriceList;

      return axios.post(url, params, config).then((res) => res.data);
    },

    postRenewalPriceList: (params) => {
      let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        url = apiEndpoints.renewalPriceList;
      return axios.post(url, params, config).then((res) => res.data);
    },

    getCovidPlans: (params = {}) =>
      axios
        .get(apiEndpoints.covidPlan, {
          params,
          paramsSerializer: (params) => {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getDenguePlans: (params = {}) => axios.get(apiEndpoints.denguePlan, { params }).then((res) => res.data),
    getPAPlans: (params = {}) => axios.get(apiEndpoints.paPlan, { params }).then((res) => res.data),

    getVendors: (params = {}) => axios.get(apiEndpoints.vendors, { params }).then((res) => res.data),

    getPAOccupations: (params = {}) =>
      axios
        .get(apiEndpoints.paOccupation, {
          params,
          paramsSerializer: (params) => {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getLetterOfGuarantee: (id) => axios.get(apiEndpoints.letterOfGuarantee + '/' + id).then((res) => res.data),

    getLetterOfGuarantees: (params = {}) => axios.get(apiEndpoints.letterOfGuarantee, params).then((res) => res.data),

    postLetterOfGuarantee: (params) => axios.post(apiEndpoints.letterOfGuarantee, params).then((res) => res.data),

    patchLetterOfGuarantee: (id, params = {}) =>
      axios.patch(apiEndpoints.letterOfGuarantee + '/' + id, params).then((res) => res.data),

    deleteLetterOfGuarantee: (id) => axios.delete(`${apiEndpoints.letterOfGuarantee}/${id}`).then((res) => res.data),

    getGradeSettings: (level_rank) => axios.get(`${apiEndpoints.gradeSettings}/${level_rank}`).then((res) => res.data),

    getAllGradeSettings: (params = {}) => axios.get(apiEndpoints.gradeSettings, { params }).then((res) => res.data),

    getNonStandardProductList: (params = {}) =>
      axios.get(apiEndpoints.nonStandardProductList, { params }).then((res) => res.data),

    getVehiclecategories: (params = {}) => axios.get(apiEndpoints.vehicleCategory, { params }).then((res) => res.data),

    getInsuranceClasses: (params = {}) => axios.get(apiEndpoints.insuranceClass, { params }).then((res) => res.data),

    getAffiliateVehicleCategories: (id) =>
      axios.get(`${apiEndpoints.affiliateVehicleCategories}/${id}`, {}).then((res) => res.data),

    getHoliday: (id) => axios.get(apiEndpoints.holidays + '/' + id).then((res) => res.data),

    getHolidays: (params = {}) => axios.get(apiEndpoints.holidays, { params }).then((res) => res.data),

    postHoliday: (params) => axios.post(apiEndpoints.holidays, params).then((res) => res.data),

    patchHoliday: (id, params = {}) => axios.patch(apiEndpoints.holidays + '/' + id, params).then((res) => res.data),

    deleteHoliday: (id) => axios.delete(`${apiEndpoints.holidays}/${id}`).then((res) => res.data),

    getCmiPremium: (params) =>
      axios
        .get(apiEndpoints.getCmiPremium, {
          params,
          paramsSerializer: (params) => {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getVehiclecategoriesManual: (params) =>
      axios
        .get(apiEndpoints.getVehicleCategoryManual, {
          params,
          paramsSerializer: (params) => {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),
  },
  policy: {
    policyLead: (params, id) =>
      axios.post(apiEndpoints.policyLead + (id ? '/' + id : ''), params).then((res) => res.data),

    postPolicy: (params, config) => axios.post(apiEndpoints.policyUpload, params, config).then((res) => res.data),

    getPolicyUploaded: () => axios.get(apiEndpoints.policyUpload, {}).then((res) => res.data),

    getPolicy: () => axios.get(apiEndpoints.policyDetails).then((res) => res.data),

    postSOADocument: (params) => axios.post(apiEndpoints.soaDocument, params).then((res) => res.data),

    postInsurerCreditTxn: (params) => axios.post(apiEndpoints.insurerCreditTxn, params).then((res) => res.data),

    getInsurerCredits: (params = {}) => axios.get(apiEndpoints.insurerCredits, { params }).then((res) => res.data),

    patchVendorKyc: (id, params) => axios.patch(`${apiEndpoints.vendorKyc}/${id}`, params).then((res) => res.data),

    postVendorKyc: (params) => axios.post(apiEndpoints.vendorKyc, params).then((res) => res.data),

    getEndorsements: (url = apiEndpoints.endorsement, params = {}) =>
      axios.get(url, { params }).then((res) => res.data),

    patchEndorsements: (id, params = {}) =>
      axios.patch(`${apiEndpoints.endorsement}/${id}`, params).then((res) => res.data),

    postEndorsements: (params = {}) => axios.post(apiEndpoints.endorsement, params).then((res) => res.data),

    deleteEndorsement: (id) => axios.delete(`${apiEndpoints.endorsement}/${id}`).then((res) => res.data),

    patchEndorsementDocument: (id, params = {}) =>
      axios.patch(`${apiEndpoints.endorsementDocument}/${id}`, params).then((res) => res.data),

    postEndorsementDocument: (params = {}) =>
      axios.post(apiEndpoints.endorsementDocument, params).then((res) => res.data),
    deleteEndorsementDocument: (id) =>
      axios.delete(`${apiEndpoints.endorsementDocument}/${id}`).then((res) => res.data),

    manualEkycTransfer: (params = {}) => axios.post(apiEndpoints.manualEkycTransfer, params).then((res) => res.data),

    postNonStandardPolicy: (params = {}) => axios.post(apiEndpoints.nonStandardPolicy, params).then((res) => res.data),

    patchNonStandardPolicy: (id, params = {}) =>
      axios.patch(`${apiEndpoints.nonStandardPolicy}/${id}`, params).then((res) => res.data),

    getNonStandardSales: (url = apiEndpoints.nonStandardPolicy, params = {}) =>
      axios
        .get(url, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),
  },
  fairline: {
    affiliateSignup: (params) => axios.post(apiEndpoints.lineAffiliateSignup, params).then((res) => res.data),
    getLineGroupName: (params) => axios.get(apiEndpoints.lineGroupName, { params }).then((res) => res.data),
  },
  dataEntry: {
    user: (credentials) => axios.post(apiEndpoints.user, credentials).then((res) => res.data),

    patchUser: (id, params) => axios.patch(apiEndpoints.patchUser + id, params).then((res) => res.data),

    car: (id, data) => axios.post(apiEndpoints.car + id, data).then((res) => res.data),

    patchCar: (id, data) => axios.patch(apiEndpoints.car + id, data).then((res) => res.data),

    getCar: (id) => axios.get(apiEndpoints.car + id).then((res) => res.data),

    driver: (id, data) => axios.post(apiEndpoints.driver + id, data).then((res) => res.data),

    patchDriver: (id, data) => axios.patch(apiEndpoints.driver + id, data).then((res) => res.data),

    deleteDriver: (id) => axios.delete(apiEndpoints.driver + id).then((res) => res.data),

    getDriver: (id) => axios.get(apiEndpoints.driver + id).then((res) => res.data),

    policy: (data) => axios.post(apiEndpoints.policy, data).then((res) => res.data),

    makeModel: (data) => axios.post(apiEndpoints.makeModel, data).then((res) => res.data),

    linkPolicy: (data) => axios.post(apiEndpoints.linkPolicy, data).then((res) => res.data),

    insurerPolicies: (id) => axios.get(apiEndpoints.insurerPolicies + id).then((res) => res.data),

    manualUserEmail: (id) => axios.post(apiEndpoints.manualUserEmail + id).then((res) => res.data),

    getPolicy: (id) => axios.get(apiEndpoints.getPolicy + id).then((res) => res.data),

    patchPolicy: (id, params) => axios.patch(apiEndpoints.getPolicy + id, params).then((res) => res.data),

    referralCodeUpdate: (id, params) =>
      axios.post(apiEndpoints.referralCodeUpdate + id, params).then((res) => res.data),

    refetchQuotes: (id) => axios.post(apiEndpoints.refetchQuotes + id).then((res) => res.data),

    getUser: (id) => axios.get(apiEndpoints.getUser + id).then((res) => res.data),

    addQuotation: (id, params) => axios.post(apiEndpoints.addQuotation + id, params).then((res) => res.data),

    addAddons: (id, params) => axios.post(apiEndpoints.addAddons + id, params).then((res) => res.data),

    getFormLink: (params) => axios.post(apiEndpoints.getFormUrl, params).then((res) => res.data),

    getQuotation: (id) => axios.get(apiEndpoints.quotation + id).then((res) => res.data),

    getAddon: (id) => axios.get(apiEndpoints.addOn + id).then((res) => res.data),

    patchQuotation: (id, params) => axios.patch(apiEndpoints.quotation + id, params).then((res) => res.data),

    patchAddon: (id, params) => axios.patch(apiEndpoints.addOn + id, params).then((res) => res.data),

    deleteQuotation: (id) => axios.delete(apiEndpoints.quotation + id).then((res) => res.data),
  },
  billing: {
    postBankStatementFile: (params, withoutQuotation) => {
      let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        url = apiEndpoints.bankStatementFile;
      if (!withoutQuotation) {
        url = apiEndpoints.bankStatementFile + '?with_quotation=true';
      }
      return axios.post(url, params, config).then((res) => res.data);
    },
    postBatchBankStatementFile: (params) => {
      let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        url = apiEndpoints.batchBankStatement + '?with_quotation=true';

      return axios.post(url, params, config).then((res) => res.data);
    },
    getPendingPayments: (params, endpoint = apiEndpoints.billingPayment) => {
      return axios
        .get(endpoint, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },
    reconcilePayment: (id, params) => {
      return axios.patch(`${apiEndpoints.billingPayment}/${id}`, params).then((res) => res.data);
    },
    getInvoices: (params, endpoint = apiEndpoints.billingInvoices) => {
      return axios
        .get(endpoint, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },
    getInvoice: (id, params, endpoint = apiEndpoints.billingInvoices) => {
      return axios
        .get(`${endpoint}/${id}`, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },
    patchIntermediatePayments: (id, params) => {
      return axios.patch(`${apiEndpoints.intermediatePayments}/${id}`, params).then((res) => res.data);
    },
    postIntermediatePayments: (params) => {
      return axios.post(`${apiEndpoints.intermediatePayments}`, params).then((res) => res.data);
    },
    getIntermediatePayment: (id, params) => {
      return axios.get(`${apiEndpoints.intermediatePayments}/${id}`, { params }).then((res) => res.data);
    },
    postBillingpayment: (params) =>
      axios
        .post(apiEndpoints.billingPayment, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => res.data),

    deleteBillingPayment: (id) => {
      return axios.delete(`${apiEndpoints.billingPayment}/${id}`).then((res) => res.data);
    },

    deleteBillingDocument: (id) => {
      return axios.delete(`${apiEndpoints.billingPaymentFile}/${id}`).then((res) => res.data);
    },
    getSalesWithInvoices: (params, endpoint = apiEndpoints.saleTrack) => {
      return axios
        .get(endpoint, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },
    postInvoiceDetail: (params) => axios.post(apiEndpoints.invoiceDetail, params).then((res) => res.data),

    patchInvoiceDetail: (id, params) =>
      axios.patch(apiEndpoints.invoiceDetail + '/' + id, params).then((res) => res.data),

    getSaleWithInvoice: (id, params) => {
      return axios
        .get(
          `${apiEndpoints.saleTrack}/${id}?invoices__payee=fairdee&invoices__payer=insurer&invoices__payee=insurer&invoices__payer=fairdee`,
          { params }
        )
        .then((res) => res.data);
    },

    postIncentiveInvoice: (params) => axios.post(apiEndpoints.fairdeeIncentive, params).then((res) => res.data),

    deleteIP: (id) =>
      axios
        .delete(apiEndpoints.ip + '/' + id)
        .then((res) => res.data)
        .catch((err) => err),

    postPaymentStatementFile: (params) => axios.post(apiEndpoints.paymentStatementFile, params).then((res) => res.data),

    postQRRequest: (params) => axios.post(apiEndpoints.thaiQRRequest, params).then((res) => res.data),

    getQRImage: (id) => axios.get(apiEndpoints.thaiQRRequest + '/' + id).then((res) => res.data),

    getBankTransactions: (endpoint = apiEndpoints.bankTransactions, params) => {
      return axios.get(endpoint, { params }).then((res) => res.data);
    },
    getIntermediatePayments: (endpoint = apiEndpoints.intermediatePayments, params = {}) =>
      axios.get(endpoint, { params }).then((res) => res.data),

    getCombinedInvoices: (endpoint = apiEndpoints.combinedInvoice, params = {}) => {
      return axios
        .get(endpoint, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data);
    },

    getCombinedInvoice: (id, params = {}) =>
      axios.get(`${apiEndpoints.combinedInvoice}/${id}`, { params }).then((res) => res.data),
    createCombinedInvoice: (params) => axios.post(apiEndpoints.combinedInvoice, params).then((res) => res.data),

    getThaiQRRequest: (id, params = {}) =>
      axios.get(`${apiEndpoints.thaiQRRequest}/${id}`, { params }).then((res) => res.data),

    inquireThaiQrStatus: (params) => axios.post(apiEndpoints.thaiQrInquiry, params).then((res) => res.data),

    refundInvoiceDryRun: (params) => axios.post(apiEndpoints.refundInvoiceDryRun, params).then((res) => res.data),

    instalmentTransferRefundDryRun: (params) =>
      axios.post(apiEndpoints.instalmentTransferRefundDryRun, params).then((res) => res.data),

    addRemark: (params) => axios.post(apiEndpoints.invoiceRemark, params).then((res) => res.data),

    patchRemark: (id, params) => axios.patch(apiEndpoints.invoiceRemark + '/' + id, params).then((res) => res.data),

    deleteRemark: (id) => axios.delete(apiEndpoints.invoiceRemark + '/' + id).then((res) => res.data),

    getRemarks: (url = null, params) =>
      axios.get(url || apiEndpoints.invoiceRemark, { params }).then((res) => res.data),

    uploadBankTransferStatement: (params) =>
      axios.post(apiEndpoints.paymentStatementUpload, params).then((res) => res.data),

    deletePenaltyInvoice: (params) => axios.post(apiEndpoints.deletePenaltyInvoice, params).then((res) => res.data),

    billPaymentRequest: (params) => axios.post(apiEndpoints.billPaymentRequest, params).then((res) => res.data),

    getBillPaymentRequest: (id, params) =>
      axios.get(`${apiEndpoints.billPaymentRequest}/${id}`, { params }).then((res) => res.data),

    getBillPaymentStatus: (params) =>
      axios
        .get(apiEndpoints.billPaymentRequests, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    uploadWHTFile: (params) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      return axios.post(apiEndpoints.uploadWHT, params, config).then((res) => res.data);
    },
    getWHTCertificate: (params) =>
      axios
        .get(apiEndpoints.uploadWHT, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    reconcileKbankPayment: (params) => {
      return axios.post(apiEndpoints.kbankReconcilation, params).then((res) => res.data);
    },
  },
  communicator: {
    postFCMToken: (params) => axios.post(apiEndpoints.fcmToken, params).then((res) => res.data),

    deleteFCMToken: (id, params) => axios.delete(`${apiEndpoints.fcmToken}/${id}`, { params }).then((res) => res.data),

    uploadCsv: (params) => {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      return axios.post(apiEndpoints.uploadCsv, params, config).then((res) => res.data);
    },
  },
  apiHelper: {
    source,
  },
  survey: {
    postSurvey: (params) => axios.post(apiEndpoints.survey, params).then((res) => res.data),

    getSurveyResponse: (id, params) =>
      axios.get(apiEndpoints.surveyResponse + '/' + id, { params }).then((res) => res.data),

    postSurveyResponse: (params = {}) => axios.post(apiEndpoints.surveyResponse, params).then((res) => res.data),

    patchSurveyResponse: (id = undefined, params = {}) =>
      axios.patch(apiEndpoints.surveyResponse + '/' + id, params).then((res) => res.data),
  },
  profiles: {
    addGrade: (params) => axios.post(apiEndpoints.grade, params).then((res) => res.data),

    patchGrade: (id, params) => axios.patch(`${apiEndpoints.grade}/${id}`, params),

    levelUpgradeRequest: (params) => axios.post(apiEndpoints.levelUpgradeRequest, params).then((res) => res.data),

    getReferrerAffiliate: (params) =>
      axios
        .get(`${apiEndpoints.referrerAffiliate}`, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),
    getReferrerAffiliate: (params) =>
      axios
        .get(`${apiEndpoints.referrerAffiliate}`, {
          params,
          paramsSerializer: function (params) {
            return Qs.stringify(params, { indices: false });
          },
        })
        .then((res) => res.data),

    getAffiliateCommissionReport: (params) =>
      axios
        .get(`${apiEndpoints.affiliateCommissionReport}`, {
          params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    getTeamCommissionReport: (params) =>
      axios
        .get(`${apiEndpoints.teamCommissionReport}`, {
          params,
          paramsSerializer: (params) => Qs.stringify(params, { indices: false }),
        })
        .then((res) => res.data),

    updateAffiliateEmail: (params) => axios.post(`${apiEndpoints.updateEmail}`, params).then((res) => res.data),
  },
  system: {
    getSystemSettings: () => axios.get(apiEndpoints.systemSettings).then((res) => res.data),
  },
};
