import { S3_URL } from './config';

export const POLICY_STATUSES = [
  'docs_collected',
  'docs_approved',
  'docs_rejected',
  'coa_approval_pending',
  'renewal_waiting_to_issue_policy',
  'policy_uploaded',
  'policy_sent_to_user',
  'docs_pending',
  'covernote_issued',
  'policy_received_by_vendor',
  'policy_sent_to_vendor',
  'send_policy_to_user',
  'need_to_cancel_cmi',
];

export const CORONA_POLICY_STATUSES = [
  'docs_pending',
  'docs_collected',
  'docs_approved',
  'docs_rejected',
  'insurer_notified',
  'policy_issued',
  'cancellation_requested',
  'cancellation_requested_insurer',
  'cancellation_completed',
];

export const DENGUE_POLICY_STATUSES = [
  'docs_pending',
  'docs_collected',
  'docs_approved',
  'docs_rejected',
  'insurer_notified',
  'policy_issued',
  'cancellation_requested',
  'cancellation_requested_insurer',
  'cancellation_completed',
];

export const PA_POLICY_STATUSES = [
  'docs_pending',
  'docs_collected',
  'docs_approved',
  'docs_rejected',
  'insurer_notified',
  'policy_issued',
  'cancellation_requested',
  'cancellation_requested_insurer',
  'cancellation_completed',
  'sent_for_consideration_occupation',
  'sent_for_consideration_declaration',
  'insurer_rejected',
];

export const RENEWAL_STATUSES = [
  'price_pending',
  'revision_pending',
  'renewal_rejected',
  'price_ready',
  'revision_required',
];

export const PAYMENT_STATUSES = [
  'invoice_issued',
  'insurer_notified',
  'payment_verified',
  'commission_transferred',
  'transfer_note_issued',
  'payment_proof_received',
  'payment_verified1',
  'credit_time_exceeded',
  'credit_approved',
];

export const DENGUE_PAYMENT_STATUSES = [
  'invoice_issued',
  'payment_proof_received',
  'payment_verified',
  'commission_transferred',
  'payment_refunded',
  'no_refunds',
];

export const PA_PAYMENT_STATUSES = [
  'invoice_issued',
  'payment_proof_received',
  'payment_verified',
  'commission_transferred',
  'payment_refunded',
  'no_refunds',
];

export const CORONA_PAYMENT_STATUSES = [
  'invoice_issued',
  'payment_proof_received',
  'payment_verified',
  'commission_transferred',
  'payment_refunded',
  'no_refunds',
];

export const PAYMENT_STATUSES_TO_INSURER = [
  'invoice_issued',
  'insurer_notified',
  'payment_proof_received',
  'payment_verified',
  'commission_transferred',
];

export const PAYMENT_STATUSES_TO_FAIRDEE = [
  'invoice_issued',
  'payment_proof_received',
  'payment_verified1',
  'commission_transferred1',
];

export const PAYMENT_STATUSES_TO_FAIRDEE_CREDIT = [
  'invoice_issued',
  'payment_proof_received',
  'payment_verified1',
  'credit_approved',
];

export const EKYC_REJECTED = 'ekyc_rejected';

export const PAYMENT_METHODS = ['bank_account', 'credit_card'];

export const PAYMENT_STATUSES_CREDIT_EXCEEDED = ['credit_time_exceeded'];

export const EKYC_STATUSES = [
  'ekyc_pending_verification',
  'ekyc_verified',
  'ekyc_rejected',
  'ekyc_pending',
  'loan_contract_signed',
];

export const INSURANCE_CLASSES = ['1', '2', '3', '2+', '3+'];

export const SALE_TYPES = ['cbc_to_fairdee', 'cbc_to_insurer', 'credit', 'credit_exceeded'];

export const INVOICE_PAYMENT_STATUSES = ['unpaid', 'underpaid', 'overpaid', 'fully_paid'];

export const ENDORSEMENT_STATUSES = [
  'receive_supporting_documents',
  'docs_approved',
  'endorsement_docs_uploaded',
  'endorsement_doc_sent_to_vendor',
  'endorsement_doc_received_by_vendor',
  'endorsement_doc_received_by_customer',
  'endorsement_requested',
  'endorsement_issued',
  'endorsement_rejected',
  'endorsement_request_sent_to_insurer',
];

export const ENDORSEMENT_PAYMENT_STATUSES = [
  'invoice_issued',
  'payment_proof_received',
  'payment_verified',
  'payment_to_agent_complete',
  'payment_required',
  'payment_not_required',
];

export const ENDORSEMENT_INSURER_PAYMENT_STATUSES = [
  'invoice_issued',
  'fully_settled',
  'partially_settled',
  'unsettled',
  'no_premium_change',
];

export const CAR_INSPECTION_STATUSES = ['confirmed', 'not_yet_confirmed'];

export const CREDIT_OK = 'Credit OK';

export const INSBEE = 'insbee';
export const SELFMADE = 'SelfMade';

//Temporary fix to move all new instalment sales to Vendor = Insbee
export const CURRENT_VENDOR = INSBEE;
//2 is the vendor ID for Insbee. Fallback just in case vendors don't get loaded for any reason
export const FALLBACK_VENDOR_ID = 2;

export const INVOICE_ISSUED = 'invoice_issued';
export const PAYMENT_VERIFIED = 'payment_verified';
export const COMMISSION_TRANSFERRED = 'commission_transferred';
export const PAYMENT_PROOF_RECEIVED = 'payment_proof_received';
export const CREDIT_APPROVED = 'credit_approved';
export const REFUND_ENDORSEMENT = 'refund_endorsement';

export const VANILLA_PAID_STATUSES = [PAYMENT_VERIFIED, COMMISSION_TRANSFERRED];
export const VANILLA_PAYMENT_STATUSES = [PAYMENT_VERIFIED, COMMISSION_TRANSFERRED, PAYMENT_PROOF_RECEIVED];
export const ALL_ENCOMPASSING_PAYMENT_STATUSES = [
  INVOICE_ISSUED,
  PAYMENT_VERIFIED,
  COMMISSION_TRANSFERRED,
  PAYMENT_PROOF_RECEIVED,
  CREDIT_APPROVED,
];
export const ALL_ENCOMPASSING_PAID_STATUSES = [PAYMENT_VERIFIED, COMMISSION_TRANSFERRED, CREDIT_APPROVED];

export const COMMISSION_SCHEDULES = [
  {
    key: 'after_first_instalment',
    value: 'after_first_instalment',
    text: 'After First Instalment is Paid',
  },
  {
    key: 'after_second_instalment',
    value: 'after_second_instalment',
    text: 'After Second Instalment is Paid',
  },
  {
    key: 'after_payment_verified',
    value: 'after_payment_verified',
    text: 'After Sale is Paid',
  },
  {
    key: 'per_instalment_payment',
    value: 'per_instalment_payment',
    text: 'After Instalment is Paid',
  },
];

export const COMMISSION_SCHEDULES_THAI_TEXT = [
  {
    key: 'after_second_instalment',
    value: 'after_second_instalment',
    text: 'จ่ายเต็มในงวดที่ 2',
  },
  {
    key: 'after_payment_verified',
    value: 'after_payment_verified',
    text: 'จ่ายเต็มเมื่อผ่อนครบ',
  },
  {
    key: 'per_instalment_payment',
    value: 'per_instalment_payment',
    text: 'จ่ายแบบเฉลี่ยทุกงวด',
  },
];

export const FAIRDEE_CONTACT_NUMBER = '021147920';
export const FAIRDEE_CONTACT_NUMBER_LABEL = '02-114-7920';
export const FAIRDEE_FB_URL = 'https://www.facebook.com/FairDeecoth-139910703540258';
export const FAIRDEE_LINE_URL = 'https://line.me/R/ti/p/%40cfy8810t';
export const FAIRDEE_MLM_LINE_URL = 'https://lin.ee/Xz86RIn';
export const FAIRDEE_LINE_ID = '@cfy8810t';
export const FAIRDEE_MLM_LINE_ID = '@fairdeeplus';
export const PROMOTION_DAYS = ['2021-06-04', '2021-06-05', '2021-06-12', '2021-06-19', '2021-06-26'];
export const BREAKUP_MAP = {
  2: 3,
  3: 4,
  4: 6,
};

export const COMMISSION_TITLES = [
  'ค่าคอมงวดที่ 1',
  'ค่าคอมงวดที่ 2',
  'ค่าคอมงวดที่ 3',
  'ค่าคอมงวดที่ 4',
  'ค่าคอมงวดที่ 5',
  'ค่าคอมงวดที่ 6',
];
export const FREE_VACCINE_PLAN = 'ประกันภัยการแพ้วัคซีนโควิท (แผน 1)';
export const LICENSE_Modal_EXCLUDED_ACCOUNTS = ['inspection_garage', 'subagent'];
export const REJECTED_SALE_STATUS = ['docs_rejected', 'car_inspection_rejected', 'ci_rejected'];

export const CLAIM_OPTIONS = {
  Yes: true,
  No: false,
  Unknown: 'unknown',
};
export const THEFT_SUPPORTED_INSURANCE_CLASSES = ['2+', '3+'];

export const VMI_SUBAGENT_PRODUCTS = ['voluntary_insurance'];
export const VMI_SUBAGENT_INSURANCE_CLASSES = ['2+', '3', '3+'];

export const VMI_SUBAGENT_INSURER = ['indara2', 'indara', 'vib', 'TSI', 'TMTH', 'bki'];
export const CMI_SUBAGENT_INSURER = ['indara2', 'indara', 'vib', 'TSI', 'TMTH', 'bki'];
export const CMI_SUBAGENT_PRODUCTS = ['online_compulsory_insurance', 'compulsory_insurance'];
export const CMI_COMMISSION_TYPES = [
  'online_compulsory_commission',
  'online_compulsory_commission_non_1_vehicle_category',
  'compulsory_commission',
];

export const CMI_PRODUCTS = ['online_compulsory_commission', 'compulsory_commission'];
export const COMPLETELY_PAID_INVOICE_STATUSES = ['fully_paid', 'overpaid'];
export const DEFAULT_SIGNUP = ['phone']; // options phone, email // had to use array for this Typescript error This condition will always return 'true' since the types have no overlap

export const CMI_INSURERS = ['indara2', 'tsi', 'vib', 'tmth', 'axa', 'ici'];
export const CANCELLATION_SUPPORT_INSURERS = [
  'asia',
  'indara2',
  'indara',
  'tsi',
  'tmth',
  'vib',
  'bki',
  'axa',
  'ici',
  'tpb',
];
export const SAME_DAY_CANCELLATION_INSURERS = ['asia', 'indara2', 'tsi', 'indara', 'tmth', 'axa', 'ici', 'tpb'];

export const ADDITIONAL_VEHICLE_PROVINCE = [
  {
    provincecode: '04',
    provincename: 'เบตง',
  },
];
export const IS_RED_VEHICLE_NUMBERS = ['ป้ายแดง', 'ปด', 'ป้ายแดง-', 'ปด-', 'ใหม่'];
export const API_PROVINCE_INSURER = ['indara', 'vib', 'tsi', 'bki', 'indara2'];
export const UPLOAD_SUM_INSURED = 'upload_sum_insured';
export const UPLOAD_RENEWAL_PRICE_LIST = 'upload_renewal_price_list';

export const COA_EXCLUDED_INSURER_NAMES = ['สินทรัพย์ประกันภัย', 'เอ็มเอสไอจี', 'ฟอลคอนประกันภัย'];
export const MAX_SUM_INSURED = 1000000;
export const VOLUNTARY_INSURANCE = 'voluntary_insurance';
export const COMPULSORY_INSURANCE = 'compulsory_insurance';
export const TRUCK_INSURANCE = 'truck_insurance';
export const ONLINE_COMPULSORY_INSURANCE = 'online_compulsory_insurance';
export const COVID_INSURANCE = 'covid_insurance';
export const VACCINE_INSURANCE = 'vaccine_insurance';
export const DENGUE_INSURANCE = 'dengue_insurance';
export const MOTORBIKE_INSURANCE = 'motorbike_insurance';
export const TAXI_INSURANCE = 'taxi_insurance';
export const PA_INSURANCE = 'pa_insurance';
export const OTHERS = 'others';
export const PRODUCT_CHOICES = [
  ONLINE_COMPULSORY_INSURANCE,
  COMPULSORY_INSURANCE,
  VOLUNTARY_INSURANCE,
  TRUCK_INSURANCE,
  MOTORBIKE_INSURANCE,
  TAXI_INSURANCE,
  OTHERS,
];
export const SUBAGENT_PRODUCT_CHOICES = [ONLINE_COMPULSORY_INSURANCE, COMPULSORY_INSURANCE, VOLUNTARY_INSURANCE];
export const NON_MOTOR_PRODUCTS = [COVID_INSURANCE, VACCINE_INSURANCE, DENGUE_INSURANCE, PA_INSURANCE];
export const COMPULSORY_MOTOR_PRODUCTS = [ONLINE_COMPULSORY_INSURANCE, COMPULSORY_INSURANCE];
export const VOLUNTARY_MOTOR_PRODUCTS = [VOLUNTARY_INSURANCE, TRUCK_INSURANCE, MOTORBIKE_INSURANCE, TAXI_INSURANCE];

export const API_LIMIT = 10;
export const VMI_IG_REPORT = 'vmi_ig_report';
export const INSPECTION_GARAGE = 'inspection_garage';
export const SUBAGENT = 'subagent';
export const VMI_REPORT_ELIGIBLE_ACCOUNTS = [INSPECTION_GARAGE, SUBAGENT];
export const THAI_COUNTRY_ID = 1;
export const AXA_REGISTERED_TYPES = {
  '1.10': '1',
  '1.10E': '1',
  '1.20A': '2',
  '1.40A': '3',
};
export const PAYMENT_POLLING_TIMER = 30000;
export const CHATWOOT_POLLING_TIMER = 20000;
export const STOP_POLLING_TIMER = 300000;
export const CUSTOM_AFFILIATE_COMMISSION_RATE = 'custom_affiliate_commission_rate';
export const MASTER_AFFILIATE_COMMISSION_RATE = 'master_affiliate_commission_rate';
export const OCR_ALLOWED_FILE_EXT = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'application/pdf'];
export const OCR_FILE_LIMIT = 1250000; // 10 MB in byte
export const OCR_VEHICLE_TYPE_MAPPING = {
  'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน': '110',
  'รถยนต์นั่งส่วนบุคคลเกิน 7 คน': '210',
  รถยนต์บรรทุกส่วนบุคคล: '320',
  'รถยนต์รับจ้างบรรทุกคนโดยสารไม่เกิน 7 คน': '730',
  รถยนต์สี่ล้อเล็กรับจ้าง: '120',
  รถยนต์บริการธุรกิจ: '120',
  รถยนต์บริการทัศนาจร: '120',
  รถยนต์บริการให้เช่า: '120',
  รถจักรยานยนต์: '610',
  รถบดถนน: '320.1',
  รถพ่วง: '520',
  รถจักรยานยนต์สาธารณะ: '630',
};

export const APPMAN_ERROR_CODES = ['error_code', 'error_ui_msg', 'error_msg'];
export const APPMAN = 'appman';
export const MANDATORY_ENGINE_NUMBER_INSURER = ['nki', 'upp'];

export const ASPECT_RATIO_MAPPING = {
  promotion: '2.14',
  commission: '0.45',
};
export const FAIRDEE = 'fairdee';
export const AFFILIATE = 'affiliate';
export const NO_PRINT_REQUIRED = 'no_print_required';
export const SPECIAL_PRINT_PREFERRENCE_INSURER = ['TNI', 'SMK', 'AZAY'];

export const MANUAL_POLICY_STATUSES = [
  'pending',
  'request sent to all insurers',
  '1 quotation added',
  '2 quotation added',
  '3 quotation added',
  'all quotation added',
  'rejected by insurer',
  'docs missing',
  'quotation shared with agent',
  '1 followup with insurer',
  '2 followup with insurer',
  '1 followup with agent',
  '2 followup with agent',
  'could not convert',
];
export const LEAD_TYPES_OPTIONS = ['manual', 'system', 'manual_brochure'];
export const CREATED_BY_OPTIONS = ['admin', 'agent'];
export const RESTRICTED_CHARACTERS_VEHICLE_NUMBER = ['ฏ', 'ฃ', 'ฅ', 'ซ'];
export const RESTRICTED_CHARACTERS_CHASIS_NUMBER = ['I', 'O', 'Q'];
export const MANUAL_QUOTATION_DISABLED_INSURERS = ['sei', 'thi'];
export const PRINTING_BACKLOG_DAY_FILTER_RANGE = 7;
export const WHT_EXEMPTED_AGENTS = ['inspection_garage', 'ao_agent'];

export const codeToInspectionFormMap = {
  smk: `${S3_URL}docs/smk-inspection-form-v2.pdf`,
  asia: `${S3_URL}docs/asia-form.pdf`,
  vib: `${S3_URL}docs/vib-inspection-form-v2.pdf`,
  tpb: `${S3_URL}docs/tpb-inspection-form-v2.pdf`,
  sei: `${S3_URL}docs/sei-inspection-form.pdf`,
  msig: `${S3_URL}docs/msig-inspection-form-v2.pdf`,
  tip: `${S3_URL}docs/tip-inspection-form-v2.pdf`,
  chub: `${S3_URL}docs/chub-inspection-form-v2.pdf`,
  aioi: `${S3_URL}docs/aioi-inspection-form-v2.pdf`,
  azay: `${S3_URL}docs/azay-inspection-form-v3.pdf`,
  kpi: `${S3_URL}docs/kpi-inspection-form-v2.pdf`,
  lmg: `${S3_URL}docs/lmg-inspection-form-v2.pdf`,
  mti: `${S3_URL}docs/mti-inspection-form-v2.pdf`,
  tvi: `${S3_URL}docs/tvi-inspection-form-v2.pdf`,
  upp: `${S3_URL}docs/upp-inspection-form-v2.pdf`,
  tni: `${S3_URL}docs/tni_car_inspection_form.pdf`,
  indara2: `${S3_URL}docs/indara2_car_inspection_form.pdf`,
};

export const NON_MOTOR_COUNTRY_CODE = 'TH';
export const NON_MOTOR_BUSINESS_UNIT = 'FAIRDEE';

export const PAYMENT_METHODS_LIST = [
  {
    key: 'QR Code',
    value: 'QR Code',
    text: 'QR Code',
    recommended: true,
    recommendedText: 'Recommended',
  },
  {
    key: 'Bank Transfer',
    value: 'Bank Transfer',
    text: 'Transfer',
  },
];

export const PAYMENT_AMOUNTS = [
  {
    key: 'Full Premium',
    value: 'Full Premium',
    text: 'Full Premium',
  },
  {
    key: 'Commission Deducted',
    value: 'Commission Deducted',
    text: 'Commission Deducted',
  },
];

export const IS_INSTALMENT_TYPE = [
  {
    key: 'No',
    value: false,
    text: 'instalmentNo',
  },
  {
    key: 'Yes',
    value: true,
    text: 'instalmentYes',
  },
];

export const TRANSFER_TYPE_LIST = [
  {
    key: 'Bill Payment',
    value: 'Bill Payment',
    text: 'Bill Payment',
    recommended: true,
    recommendedText: 'Recommended',
  },
  {
    key: 'Bank Transfer',
    value: 'Bank Transfer',
    text: 'Bank Transfer',
  },
];

export const BANK_ACCOUNT = 'bank_account';
export const CREDIT = 'credit';
export const BILL_PAYMENT = 'bill_payment';
export const QR_TRANSFER = 'qr_transfer';

export const PLATFORM_PRODUCTS = [
  {
    code: 'TRAVEL',
    key: 'travel',
    category: 'TRAVEL',
    label: 'Travel Insurance',
    iconClass: 'fa fa-plane',
    search_key: 'travel_insurance',
    summary: {
      number_of_passengers: 'No. of Travellers',
      travel_start_date: 'Departure Date',
      travel_end_date: 'Return Date',
      max_day_gte: 'No. of Days',
    },
  },
  {
    code: 'PA',
    key: 'pa',
    category: 'MICROHEALTH',
    label: 'Personal Accident',
    iconClass: 'fa fa-wheelchair',
    search_key: 'pa_insurance',
  },
  {
    code: 'MOTOR_BIKE',
    key: 'motor_bike',
    category: 'MOTOR',
    label: 'Motor Bike',
    iconClass: 'fa fa-motorcycle',
    search_key: 'motorbike_insurance',
  },
  {
    code: 'FIRE',
    key: 'fire',
    category: 'FIRE',
    label: 'Fire Insurance',
    iconClass: 'fa fa-fire',
    search_key: 'fire_insurance',
  },
];

export const PLATFORM_FORM_DOCUMENTS = [
  'national_id_document',
  'passport_document',
  'vehicle_registration_document',
  'id_card_passport_company_registration_doc',
  'property_registration_doc',
  'survey_report_doc',
  'supporting_doc',
  'photo_doc',
  'previous_insurance_doc',
  'application_form_doc',
];
export const DEDUCTION_REASONS = {
  'Create Quotation': 'Quotation creation',
  'Quotation Query Creation': 'Quotation Query Creation',
  'Package Selection': 'Package selection or Report sale button',
  'Report Sale Button': 'Package selection or Report sale button',
  'Manual Billing': 'Manual Billing/Invoice creation',
  'Continue button at Step 1': 'Continue button at Step 1',
  'Continue button at Step 2': 'Continue button at Step 2',
  'Continue button at Step 3': 'Continue button at Step 3',
  'Continue button at Step 4': 'Continue button at Step 4',
  'Continue button at Step 5': 'Continue button at Step 5',
  'Continue button at Step 5 plus': 'Continue button at Step 5 plus',
};

export const DISABLED_INSURER_EMAIL_POLICY_STATUS = ['docs_rejected', 'policy_uploaded', 'policy_sent_to_user'];

export const validFileFormat = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

export const VEHICLE_CATEGORY_VMI_TO_CMI_MAPPING = {
  110: '1.10',
  '210.v': '1.20a',
  320: '1.40a',
  610: '1.30b',
  420: '1.50',
  520: '1.60',
  730: '3.10',
};

export const INSPECTION_STATUSES = {
  DRAFT: {
    key: 'inspection_pending',
    text: 'Inspection Pending',
  },
  UNDER_REVIEW_WITH_BROKER: {
    key: 'submission_under_review_by_fairdee',
    text: 'Submission Under Review By Fairdee',
  },
  UNDER_REVIEW_WITH_INSURER: {
    key: 'submission_under_review_by_insurer',
    text: 'Submission Under Review By Insurer',
  },
  ENQUIRY_BY_BROKER: {
    key: 'inspection_resubmission_required',
    text: 'Inspection Resubmission Required (by Broker)',
  },
  ENQUIRY_BY_INSURER: {
    key: 'inspection_resubmission_required',
    text: 'Inspection Resubmission Required (by Insurer)',
  },
  REJECTED: {
    key: 'inspection_rejected',
    text: 'Inspection Rejected',
  },
  APPROVED: {
    key: 'inspection_accepted',
    text: 'Inspection Accepted',
  },
  ASSESSMENT_ON_PROGRESS: { key: 'Assessment in Progress', text: 'Assessment in Progress' },
  WAITING_FOR_CONSENT: { key: 'Waiting for Consent', text: 'Waiting for Consent' },
  USER_REJECTED: { key: 'User Rejected', text: 'User Rejected' },
};
//instalment downpayment invoices statuses
export const INST_DWN_INV_STATUSES = ['underpaid', 'overpaid', 'fully_paid', 'combined_fully_paid'];
