/* global mobileClient */
import axios from 'axios';
import commaNumber from 'comma-number';
import $ from 'jquery';
import jsPDF from 'jspdf';
import moment from 'moment-timezone';
import validator from 'validator';
import { setAffiliate } from '../actions/affiliate';
import { logout, userData } from '../actions/auth';
import { setLanguage as setLanguageRedux } from '../actions/language';
import { showNotification } from '../actions/notification';
import { currentInsurerMap, currentInsurers } from '../commonjs/commonData';
import {
  ALL_ENCOMPASSING_PAID_STATUSES,
  ALL_ENCOMPASSING_PAYMENT_STATUSES,
  FAIRDEE_LINE_ID,
  FAIRDEE_LINE_URL,
  FAIRDEE_MLM_LINE_ID,
  FAIRDEE_MLM_LINE_URL,
  IS_RED_VEHICLE_NUMBERS,
  RESTRICTED_CHARACTERS_VEHICLE_NUMBER,
  VEHICLE_CATEGORY_VMI_TO_CMI_MAPPING,
  WHT_EXEMPTED_AGENTS,
} from '../constants';
import { CLIENT_ID, CLIENT_SECRET, NEW_PORTAL } from '../constants/config';
import { store } from '../index';

let LOCAL_ITEMS = [
  'accessToken',
  'language',
  'statusFilter',
  'user',
  'inlineFilterData',
  'compareData',
  'filters',
  'insurerId',
  'insurerName',
  'userId',
  'carId',
  'quotationQueryId',
  'quotationId',
  'pipDateFilter',
  'pipFilter',
  'leadsFilter',
  'nonMotorFilter',
  'nonMotorFormFilter',
  'coaFilter',
  'refreshToken',
  'referralsFilter',
  'firebaseToken',
  'firebaseTokenId',
  'lineTarget',
  'is_mlm',
  'currentAffiliate',
  'source',
  'qr_data',
  'income_selected_date',
  'prepaid_qr_data',
  'qrPaidNotification',
  'subagent_portal_filters',
];

export const validHostNames = {
  'fairdee.co.th': true,
  'www.fairdee.co.th': true,
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParametersBySubstring = (name, url) => {
  if (!url) url = window.location.href;
  /*eslint-disable */
  name = name.replace(/[\[\]]/g, '\\$&');
  /*eslint-enable */
  let parts = window.location.search.slice(1).split('&');
  let result = [];
  parts.forEach((pair) => {
    pair = pair.split('=');
    if (pair[0].indexOf(name) > -1) {
      result.push(decodeURIComponent(pair[1]));
    }
  });
  return result.length ? result : null;
};

// const STORAGE_TYPE = window.location.pathname === '/line-landing' ? 'session' : 'local';
const STORAGE_TYPE = 'local';
export const setLocalData = (key, value, storage_type = 'local') => {
  if (!['local', 'session'].includes(storage_type)) {
    storage_type = STORAGE_TYPE;
  }
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }

  if (storage_type === 'local') {
    window.localStorage.setItem(key, value);
  } else if (storage_type === 'session') {
    window.sessionStorage.setItem(key, value);
  }
};

export const getLocalData = (key, storage_type = 'local') => {
  if (!['local', 'session'].includes(storage_type)) {
    storage_type = STORAGE_TYPE;
  }
  if (storage_type === 'local') {
    let returnData = window.localStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  } else if (storage_type === 'session') {
    let returnData = window.sessionStorage.getItem(key) || null;
    try {
      returnData = JSON.parse(returnData);
    } catch (e) {
      return returnData;
    }
    return returnData;
  }
};

if (!Boolean(getLocalData('rememberMe'))) {
  LOCAL_ITEMS.push('accessToken');
}

export const deleteLocalData = (key, storage_type = 'local') => {
  if (!['local', 'session'].includes(storage_type)) {
    storage_type = STORAGE_TYPE;
  }
  if (storage_type === 'local') {
    window.localStorage.removeItem(key);
  } else if (storage_type === 'session') {
    window.sessionStorage.removeItem(key);
  }
};

export const clearLocalData = (storage_type = 'local') => {
  if (!['local', 'session'].includes(storage_type)) {
    storage_type = STORAGE_TYPE;
  }
  if (storage_type === 'local') {
    LOCAL_ITEMS.forEach((item) => {
      window.localStorage.removeItem(item);
    });
    // window.localStorage.clear();
  } else if (storage_type === 'session') {
    LOCAL_ITEMS.forEach((item) => {
      window.sessionStorage.removeItem(item);
    });
    // window.sessionStorage.clear();
  }
};

export const getAccessToken = () => {
  return getLocalData('accessToken') || null;
};

export const setAccessToken = (token) => {
  setLocalData('accessToken', token);
};

export const setRefreshToken = (token) => {
  setLocalData('refreshToken', token);
};

export const getRefreshToken = () => {
  return getLocalData('refreshToken') || null;
};

export const getInlineFilterData = () => {
  return getLocalData('inlineFilterData');
};

export const rememberMe = (bool) => {
  setLocalData('rememberMe', bool);
};

export const getRememberMe = () => {
  return getLocalData('rememberMe');
};

export const forgetMe = () => {
  setLocalData('rememberMe', false);
};

export const setInlineFilterData = (data) => {
  setLocalData('inlineFilterData', data);
};

export const getStatusFilter = () => {
  return getLocalData('statusFilter');
};

export const setStatusFilter = (data) => {
  setLocalData('statusFilter', data);
};

export const getCreatedByFilter = () => {
  return getLocalData('createdByFilter');
};

export const setCreatedByFilter = (data) => {
  setLocalData('createdByFilter', data);
};

export const getLeadsTypeFilter = () => {
  return getLocalData('leadsTypeFilter');
};

export const setLeadsTypeFilter = (data) => {
  setLocalData('leadsTypeFilter', data);
};

export const getStatusFilterPrintingBacklog = () => {
  return getLocalData('statusFilterPrintingBacklog');
};

export const getStatusFilterPrintingBacklogGQL = () => {
  return getLocalData('statusFilterPrintingBacklog-GQL');
};

export const setStatusFilterPrintingBacklog = (data) => {
  return setLocalData('statusFilterPrintingBacklog', data);
};

export const setStatusFilterPrintingBacklogGQL = (data) => {
  return setLocalData('statusFilterPrintingBacklog-GQL', data);
};

export const getStatusFilterCorona = () => {
  return getLocalData('statusFilterCorona');
};

export const setStatusFilterCorona = (data) => {
  setLocalData('statusFilterCorona', data);
};

export const getStatusFilterDengue = () => {
  return getLocalData('statusFilterDengue');
};

export const setStatusFilterDengue = (data) => {
  setLocalData('statusFilterDengue', data);
};

export const getStatusFilterNonStandard = () => {
  return getLocalData('statusFilterNonStandard');
};

export const setStatusFilterNonStandard = (data) => {
  setLocalData('statusFilterNonStandard', data);
};

export const getStatusFilterManualLeads = () => {
  return getLocalData('statusFilterManualLeads');
};

export const setStatusFilterManualLeads = (data) => {
  setLocalData('statusFilterManualLeads', data);
};

export const getStatusFilterLeads = () => {
  return getLocalData('statusFilterLeads');
};
export const getStatusFilterNonMotor = () => {
  return getLocalData('statusFilterNonMotor');
};

export const setStatusFilterNonMotor = (data) => {
  setLocalData('statusFilterNonMotor', data);
};

export const setStatusFilterLeads = (data) => {
  setLocalData('statusFilterLeads', data);
};

export const getStatusFilterPA = () => {
  return getLocalData('statusFilterPA');
};

export const setStatusFilterPA = (data) => {
  setLocalData('statusFilterPA', data);
};

export const getStatusFilterInstalment = () => {
  return getLocalData('statusFilterInstalment');
};

export const setStatusFilterInstalment = (data) => {
  setLocalData('statusFilterInstalment', data);
};

export const getPipDateFilter = () => {
  return getLocalData('pipDateFilter');
};

export const setPipDateFilter = (data) => {
  setLocalData('pipDateFilter', data);
};

export const setReferEarnModalFlag = (data) => {
  setLocalData('referEarnModalFlag', data);
};

export const getReferEarnModalFlag = () => {
  return getLocalData('referEarnModalFlag');
};

export const isLoggedIn = () => {
  return store.getState().user && store.getState().user.email ? true : false;
};

export const getVehicleDetailString = (desc, year, ignoreCC = false) => {
  let str = '-';
  if (desc) {
    str = desc.make ? snakeToTitleCase(desc.make.name) || '' : '';
    if (desc.name === 'ไม่ทราบรุ่นย่อย') {
      str += ` ${desc.make_model ? snakeToTitleCase(desc.make_model.name) || '' : ''}`;
      if (!ignoreCC) {
        str += ` ${desc.cc ? snakeToTitleCase(desc.cc.name) || '' : ''}`;
      }
    } else {
      str += ' ' + desc.name;
    }
  }

  if (year) {
    str += ' - ' + year;
  }

  return str;
};

export const getVehicleDetailStringGQL = (desc, year, ignoreCC = false) => {
  let str = '-';
  if (desc) {
    str = desc.cc.make_model.make ? snakeToTitleCase(desc.cc.make_model.make.name) || '' : '';
    if (desc.name === 'ไม่ทราบรุ่นย่อย') {
      str += ` ${desc.cc.make_model ? snakeToTitleCase(desc.cc.make_model.name) || '' : ''}`;
      if (!ignoreCC) {
        str += ` ${desc.cc ? snakeToTitleCase(desc.cc.name) || '' : ''}`;
      }
    } else {
      str += ' ' + desc.name;
    }
  }

  if (year) {
    str += ' - ' + year;
  }

  return str;
};

export const getUserData = () => {
  let userProfile = store.getState().user;

  if (userProfile && Object.keys(userProfile).length) {
    return userProfile;
  }
  return null;
};

export const setUserData = (user) => {
  setLocalData('user', user);
  store.dispatch(userData(user));
};

export const logoutHelper = () => {
  const trackEvent = window.trackEvent;
  let params = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    token: getAccessToken(),
  };
  store.dispatch(logout(params));
  store.dispatch(setAffiliate({}));
  trackEvent && trackEvent.unRegister();
};

export const setAxiosHeaders = (token = getAccessToken()) => {
  (function () {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    axios.defaults.crossDomain = true;
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  })();
};

export const setAffiliateIdHeader = (id) => {
  if (!id) {
    return;
  }
  (function () {
    if (id) {
      axios.defaults.headers.common['VOU-AFF-ID'] = id;
    }
  })();
};

export const removeAxiosHeaders = () => {
  delete axios.defaults.headers.common['Authorization'];
};

export const setLanguage = (language) => {
  setLanguageRedux(language);
  setLocalData('language', language);
};

export const getLanguage = () => {
  let language = store.getState().language || getLocalData('language') || 'thai';
  setLanguageToElement(language);
  return language;
};

export const setLanguageToElement = (lang) => {
  // $(
  //   'body, div, p, label, span, img, a, ul, li, nav, header, footer, h1, h2, h3, h4, h5, button, input, textarea, select'
  // ).attr('lang', language || 'thai');
};

export const isMobileNumber = (number) => {
  if (!number || !validator.isDecimal(number)) {
    return false;
  } else {
    if (number.length === 9 && number[0] !== '0') {
      return true;
    } else if (number.length === 10 && number[0] === '0') {
      return true;
    } else {
      return false;
    }
  }
};

export const toFixedNumber = (n, rounding = 2) => {
  if (!n) {
    return n;
  }
  let num = n.toString().replace(/\.00$/, '');
  num = num.split('.');

  if (num.length > 1) {
    return parseFloat(n).toFixed(rounding);
  }
  return parseFloat(num);
};

export const toggleBodyOverflow = () => {
  $('body').toggleClass('fixed');
};

export const toLocale = (str) => {
  if (!str) {
    return;
  }
  // return str.toLocaleString(locale || 'en');
  let parts = toFixedNumber(str).toString().split('.');
  // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return parts.join(".");
  return parts[0] + (parts[1] ? '.' + parts[1] : '');
};

export const getCompareData = () => {
  return getLocalData('compareData') || {};
};

export const removeCompareData = () => {
  deleteLocalData('compareData');
};

export const setCompareData = (compareData) => {
  setLocalData('compareData', compareData);
};

export const setFilterData = (filters) => {
  setLocalData('filters', filters);
};

export const removeFilterData = () => {
  if (getFilterData()) {
    deleteLocalData('filters');
  }
};

export const isMobileDevice = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const googleEvent = (data, to) => {
  if (to && window.ga) {
    window.ga(to + '.send', data);
  }
  if (validHostNames[window.location.hostname] && window.ga) {
    window.ga('send', data);
  }
};

export const pixelEvent = (eventName, data) => {
  if (validHostNames[window.location.hostname] && window.fbq) {
    window.fbq('track', eventName, data || {});
  }
};

export const gtagReport = (event) => {
  if (validHostNames[window.location.hostname] && window.gtag_report_conversion) {
    window.gtag_report_conversion(undefined, event);
  }
};

export const getFilterData = () => {
  return getLocalData('filters') || {};
};

export const scrollTop = (time) => {
  $('html, body').animate({ scrollTop: 0 }, time || 'fast');
};

export const scrollBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

export const scrollToElement = (elem) => {
  $(elem).animate({ scrollTop: 0 }, 'slow');
};

export const getElementY = (query, offset) => {
  if (!query) {
    return;
  }
  return window.pageYOffset + query.getBoundingClientRect().top - (offset || 0);
};

export const doScrolling = (element, duration, offset) => {
  let startingY = window.pageYOffset;
  let elementY = getElementY(element, offset);
  // If element is close to page's bottom then window will scroll only to some position above the element.
  let targetY =
    document.body.scrollHeight - elementY < window.innerHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementY;
  let diff = targetY - startingY;
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  let easing = function (t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };
  let start;

  if (!diff) return;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed miliseconds since start of scrolling.
    let time = timestamp - start;
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1);
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};

export const toDate = (d) => {
  if (!d) {
    return d;
  }
  const from = d.split('-');
  return new Date(from[2], from[1] - 1, from[0]);
};

export const validateThaiNationalId = (id) => {
  // https://github.com/jukbot/thai-national-id-validator
  if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) {
    return false;
  }
  let i,
    sum = 0;
  for (i = 0, sum = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i), 10) * (13 - i);
  }
  let check = (11 - (sum % 11)) % 10;
  if (check === parseInt(id.charAt(12), 10)) {
    return true;
  }
  return false;
};

export const validateThaiName = (val) => {
  return validator.matches(val, '^[\u0E00-\u0E7F]+$');
};

export const validateChassisNumber = (chassisNumber = '') => {
  const regex = /[^0-9a-zA-Z]/,
    len = chassisNumber.length;
  let message;
  // RESTRICTED_CHARACTERS_CHASIS_NUMBER.forEach((ch) => {
  //   if (chassisNumber.includes(ch)) {
  //     message = 'Use of I, O, Q is restricted';
  //   }
  // })

  // if (len > 17 || len < 1) {
  //   message = 'Up to 17 characters only';
  // }

  if (message) {
    return message;
  }

  if (!regex.test(chassisNumber)) {
    return true;
  }

  return 'ห้ามคีย์ช่องว่างและภาษาไทยและอักขระ พิเศษลงในช่องเลขตัวถัง';
};

export const validateEngineNumber = (engineNumber = '') => {
  const len = engineNumber.length;

  return true;
};

export const validateVehicleNumber = (vehicleNumber = '') => {
  const len = vehicleNumber.length;
  let message;
  if (vehicleNumber === 'ป้ายแดง') {
    return true;
  }
  RESTRICTED_CHARACTERS_VEHICLE_NUMBER.forEach((ch) => {
    if (vehicleNumber.includes(ch)) {
      message = 'Use of ฏ, ฃ, ฅ, ซ is restricted';
    }
  });

  if (len < 3 || len > 8) {
    message = '3-8 characters only';
  }

  if (len && isNaN(vehicleNumber[len - 1])) {
    message = 'Don’t enter province';
  }

  if (vehicleNumber.split('-').length - 1 > 1) {
    message = 'The vehicle number is wrong';
  }

  if (message) {
    return message;
  }
  return true;
};

export const validateIndaraVehicleNumber = (vehicleNumber = '') => {
  let validationResult = validateVehicleNumber(vehicleNumber);
  if (validationResult !== true) {
    return validationResult;
  }

  if (!vehicleNumber.includes('-')) {
    return 'กรุณาใส่ - เพื่อขีดขั้น ในช่องทะเบียนรถ';
  }
  return true;
};

export const validateIndara2VehicleNumber = (vehicleNumber = '') => {
  if (!vehicleNumber) {
    return 'required';
  }

  const regex = /^[0-9]{1,4}$|^[0-9]{0,2}([\u0E00-\u0E7F]{0,3})[-\s][0-9]{1,4}$/;
  if (!regex.test(vehicleNumber) && !IS_RED_VEHICLE_NUMBERS.includes(vehicleNumber)) {
    const error_regex = /^([0-9]{0,1}[\u0E00-\u0E7F]{0,3})([0-9]{1,4})$/;
    if (error_regex.test(vehicleNumber))
      return 'กรุณาใส่ - หรือเว้นว่างเพื่อขีดขั้นเลขทะเบียน เช่น 2รน-2343 หรือ 2รน 2343';
    else return 'The vehicle number is wrong';
  }

  const len = vehicleNumber.length;
  let message;
  RESTRICTED_CHARACTERS_VEHICLE_NUMBER.forEach((ch) => {
    if (vehicleNumber.includes(ch)) {
      message = 'Use of ฏ, ฃ, ฅ, ซ is restricted';
    }
  });

  if (len < 3 || len > 8) {
    message = '3-8 characters only';
  }

  if (message) {
    return message;
  }

  return true;
};

export const validateTSIVehicleNumber = (vehicleNumber = '') => {
  let validationResult = validateVehicleNumber(vehicleNumber);
  if (validationResult !== true) {
    return validationResult;
  }

  if (vehicleNumber.includes('-')) {
    return 'เลขทะเบียนรถผิด(อักษรไทย-อังกฤษและตัวเลขเท่านั้น)';
  }

  return true;
};

export const validatePassport = (passport = '') => {
  if (passport.length < 6) {
    return false;
  }
  const regex = /[^0-9A-Z]/;
  return !regex.test(passport);
};
export const validateRegistrationYear = (registration_year = '') => {
  if (!registration_year || isNaN(registration_year)) {
    return false;
  }
  let year = parseInt(registration_year);
  if (year > moment().year()) {
    return false;
  }
  return true;
};

export const validateMobileNumber = (number) => {
  if (!number || !validator.isDecimal(number)) {
    return false;
  } else {
    // Use regex to strip leading zeroes. Few Examples:
    // 0123456789 -> 123456789, 000012345 - > 12345, 1234567890 -> 1234567890, 00123000 -> 123000
    number = number.replace(/^0+/, '');
    if (number.length === 9) {
      return true;
    }
    return false;
  }
};

export const validateHouseNumber = (house_number) => {
  if (!house_number) {
    return false;
  }
  let isValid = true;
  let numbers = house_number.split('/');

  if (numbers.length > 2) {
    return false;
  }

  numbers.forEach((key) => {
    if (!validator.isNumeric(key) || !key.length) {
      isValid = false;
      return;
    }
  });
  return isValid;
};

export const validateBkiWeight = (weight, vehicle_category = '1.40a') => {
  if (!weight) {
    return false;
  }
  if (weight <= 0) {
    return false;
  }
  if (vehicle_category.toLowerCase() === '1.40b') {
    if (weight > 6000) {
      return false;
    }
  } else if (vehicle_category.toLowerCase() === '1.40c') {
    if (weight > 12000) {
      return false;
    }
  } else if (vehicle_category.toLowerCase() === '1.40d') {
    if (weight <= 12000) {
      return false;
    }
  } else {
    if (weight > 3000) {
      return false;
    }
  }
  return true;
};

export const validateMobileNumberAsia = (number) => {
  if (number && validator.isDecimal(number) && number.length === 10 && number[0] === '0') {
    return true;
  }
  return false;
};

export const validateDateFormat = () => {};

export const snakeToTitleCase = (str) => {
  if (str === 0 || !str) {
    return str;
  }
  str = str.toString();
  return str
    .split('_')
    .map((item) => item.charAt(0).toUpperCase() + item.substring(1))
    .join(' ');
};

export const formatDateTime = (val, format = 'DD/MM/YY HH:mm:ss') => moment(val).format(format);

export const formatDate = (val, format = 'DD/MM/YY') => moment(val).format(format);

export const parseDate = (val) => {
  /***
   * This function takes date and time as input in the format YYYYMMDD HH:MM:SS,
   * formats it to YYYYMMDDHHMMSS
   * and coverts it into thai date time.
   * ***/
  val = val.replace(' ', '').split(':').join('');
  return moment(val, 'YYYYMMDDHHmmss').format('lll');
};

export const validAlpha = (str) => {
  if (!str) {
    return false;
  }
  const regex = /^[a-zA-Z ]*$/;
  return regex.test(str);
};

export const setInsurerId = (id) => {
  setLocalData('insurerId', id);
};

export const getInsurerId = () => {
  return getLocalData('insurerId') || null;
};

export const setInsurerName = (name) => {
  setLocalData('insurerName', name);
};

export const getInsurerName = () => {
  return getLocalData('insurername') || null;
};

export const setUserId = (id) => {
  setLocalData('userId', id);
};

export const getUserId = () => {
  return getLocalData('userId') || null;
};

export const setCarId = (id) => {
  setLocalData('carId', id);
};

export const getQuotationQueryId = () => {
  return getLocalData('quotationQueryId') || null;
};

export const setQuotationQueryId = (id) => {
  setLocalData('quotationQueryId', id);
};

export const clearQuotationQueryId = () => {
  deleteLocalData('quotationQueryId');
};

export const setQuotationId = (id) => {
  setLocalData('quotationId', id);
};

export const getQuotationId = () => {
  return getLocalData('quotationId') || null;
};

export const removeQuotationId = () => {
  deleteLocalData('quotationId');
};

export const getCarId = () => {
  return getLocalData('carId') || null;
};

export const formattedDate = (d) => {
  if (!d) {
    return d;
  }
  const date = new Date(d);
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day}-${month}-${year}`;
};

export const getUrlparamsAsObject = () => {
  let params = {};
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  if (!query) {
    return {};
  }
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (params[pair[0]] && params[pair[0]].constructor !== Array) {
      params[pair[0]] = [params[pair[0]]];
      params[pair[0]].push(decodeURIComponent(pair[1]));
    } else if (params[pair[0]] && params[pair[0]].constructor === Array) {
      params[pair[0]].push(decodeURIComponent(pair[1]));
    } else {
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
  }
  Object.keys(params).forEach((key) => {
    if (!key) {
      delete params[key];
    }
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
};

export const toThaiDate = (date, format = 'YYYY-MM-DD') => moment(date).add(543, 'years').format(format);

export const toThaiDateTime = (date) => {
  return moment(date).add(543, 'years').format('lll');
};

export const toThaiDateTimeBase = (date, format = 'YYYY-MM-DD HH:mm:ss') =>
  moment(date).add(543, 'years').tz('Asia/Bangkok').format(format);

export const thaiShortFormat = (date) => {
  let languageMap = store.getState().languageMap.common.shortMonths;
  date = date.split(' ');

  date[1] = languageMap[date[1]];

  date = date.join('-');
  return moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

export const thaiToDefaultDate = (date, existingFormat) => {
  if (existingFormat) {
    return moment(date, existingFormat).subtract(543, 'years').format('YYYY-MM-DD');
  }
  return moment(date).subtract(543, 'years').format('YYYY-MM-DD');
};

export const thaiToDefaultYear = (year) => moment(year).subtract(543, 'years').format('YYYY');

export const thaiToDefaultDateTime = (dateTime, tzFormat) => {
  const time = moment.utc(moment.tz(dateTime, 'Asia/Bangkok')).subtract(543, 'years');
  if (tzFormat) {
    return time.format();
  } else {
    return time.format('YYYY-MM-DD HH:mm:ss');
  }
};

export const utcToThaiDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date).tz('asia/bangkok').format(format);
};

export const toUTCTime = (dateTime, tzFormat) => {
  if (!dateTime) {
    return moment.utc().format('YYYY-MM-DD HH:mm:ss');
  }
  const time = moment.utc(moment.tz(dateTime, 'Asia/Bangkok'));
  if (tzFormat) {
    return time.format();
  } else {
    return time.format('YYYY-MM-DD HH:mm:ss');
  }
};

export const toUTCTimeWithFormat = (dateTime, tzFormat) => {
  if (!dateTime) {
    return moment.utc().format('YYYY-MM-DD HH:mm:ss');
  }
  const time = moment.utc(moment.tz(dateTime, 'Asia/Bangkok'));
  return time.format(tzFormat);
};

export const getVmiDistricts = async (provinceCode, insurer_code = 'sei') => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vmi_districts/${insurer_code}_districts/${provinceCode}.json`);
  let districts = res.data;
  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });
  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtname;
    data.zip = data.zip;

    return data;
  });
};

export const getVmiSubDistricts = async (provinceCode, districtCode, insurer_code = 'sei') => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vmi_districts/${insurer_code}_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictname;
      data.zip = data.zip;
      return data;
    });
};

export const getDistrictOptions = async (provinceCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/districts/${provinceCode}.json`);
  let districts = res.data;

  return districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtname;

    return data;
  });
};

export const getDistrictOptionsAsia = async (provinceCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/asia_districts/${provinceCode}.json`);
  let districts = res.data;

  return districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtname;
    data.zip = data.zip;

    return data;
  });
};

export const getVIBnSEIDistrictOptions = async (provinceCode, is_vmi = false, insurer_code = 'sei') => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/${insurer_code}_districts/${provinceCode}.json`);
  let districts = res.data;
  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });
  if (is_vmi === true) {
    return unique_districts.map((data) => {
      data.key = data.districtcode;
      data.text = data.districtname;
      data.value = data.districtname;
      data.zip = data.zip;

      return data;
    });
  }
  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtcode;
    data.zip = data.zip;

    return data;
  });
};

export const getDistrictOptionsAxa = async (provinceCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/axa_districts/${provinceCode}.json`);
  let districts = res.data;
  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });
  if (is_vmi === true) {
    return unique_districts.map((data) => {
      data.key = data.districtcode;
      data.text = data.districtname;
      data.value = data.districtname;
      data.zip = data.zip;

      return data;
    });
  }
  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtcode;
    data.zip = data.zip;

    return data;
  });
};

export const getAxaSubDistrictOptions = async (provinceCode, districtCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/axa_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  if (is_vmi === true) {
    return sub_districts
      .filter((data) => data.districtcode === districtCode)
      .map((data) => {
        data.key = data.subdistrictcode;
        data.text = data.subdistrictname;
        data.value = data.subdistrictname;
        data.zip = data.zip;
        return data;
      });
  }
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictcode;
      data.zip = data.zip;
      return data;
    });
};

export const getDistrictOptionsTni = async (provinceCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tni_districts/${provinceCode}.json`);
  let districts = res.data;
  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });
  if (is_vmi === true) {
    return unique_districts.map((data) => {
      data.key = data.districtcode;
      data.text = data.districtname;
      data.value = data.districtname;
      data.zip = data.zip;

      return data;
    });
  }
  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtcode;
    data.zip = data.zip;

    return data;
  });
};

export const getDistrictOptionsIndara2 = async (provinceCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/indara2_districts/${provinceCode}.json`);
  let districts = res.data;
  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });
  if (is_vmi) {
    return unique_districts.map((data) => {
      data.key = data.districtcode;
      data.text = data.districtname;
      data.value = data.districtname;
      data.zip = data.zip;

      return data;
    });
  }
  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtcode;
    data.zip = data.zip;

    return data;
  });
};

export const getIndara2SubDistrictOptions = async (provinceCode, districtCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/indara2_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  if (is_vmi) {
    return sub_districts
      .filter((data) => data.districtcode === districtCode)
      .map((data) => {
        data.key = data.subdistrictcode;
        data.text = data.subdistrictname;
        data.value = data.subdistrictname;
        data.zip = data.zip;
        return data;
      });
  }
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictcode;
      data.zip = data.zip;
      return data;
    });
};

export const getTniSubDistrictOptions = async (provinceCode, districtCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tni_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  if (is_vmi === true) {
    return sub_districts
      .filter((data) => data.districtcode === districtCode)
      .map((data) => {
        data.key = data.subdistrictcode;
        data.text = data.subdistrictname;
        data.value = data.subdistrictname;
        data.zip = data.zip;
        return data;
      });
  }
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictcode;
      data.zip = data.zip;
      return data;
    });
};

export const getDistrictOptionsTSI = async (provinceCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tsi_districts/${provinceCode}.json`);
  let districts = res.data;
  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });
  if (is_vmi === true) {
    return unique_districts.map((data) => {
      data.key = data.districtcode;
      data.text = data.districtname;
      data.value = data.districtname;
      data.zip = data.zip;

      return data;
    });
  }
  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtcode;
    data.zip = data.zip;

    return data;
  });
};

export const getDistrictOptionsIndara = async (provinceCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/indara_districts/${provinceCode}.json`);
  let districts = res.data;

  return districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtname;
    data.zip = data.zip;

    return data;
  });
};

export const getDistrictOptionsBKI = async (provinceCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/bki_districts/${provinceCode}.json`);
  let districts = res.data;

  const unique_districts = [];
  const unique_district_codes = [];
  districts.map((data) => {
    if (unique_district_codes.indexOf(data.districtcode) === -1) {
      unique_districts.push(data);
      unique_district_codes.push(data.districtcode);
    }
  });

  return unique_districts.map((data) => {
    data.key = data.districtcode;
    data.text = data.districtname;
    data.value = data.districtname;
    data.zip = data.zip;

    return data;
  });
};

export const getVIBnSEISubDistrictOptions = async (
  provinceCode,
  districtCode,
  is_vmi = false,
  insurer_code = 'sei'
) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/${insurer_code}_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  if (is_vmi === true) {
    return sub_districts
      .filter((data) => data.districtcode === districtCode)
      .map((data) => {
        data.key = data.subdistrictcode;
        data.text = data.subdistrictname;
        data.value = data.subdistrictname;
        data.zip = data.zip;
        return data;
      });
  }
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictcode;
      data.zip = data.zip;
      return data;
    });
};

export const getSubDistrictOptionsTSI = async (provinceCode, districtCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tsi_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  if (is_vmi === true) {
    return sub_districts
      .filter((data) => data.districtcode === districtCode)
      .map((data) => {
        data.key = data.subdistrictcode;
        data.text = data.subdistrictname;
        data.value = data.subdistrictname;
        data.zip = data.zip;
        return data;
      });
  }
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictcode;
      data.zip = data.zip;
      return data;
    });
};

export const getSubDistrictOptionsBKI = async (provinceCode, districtCode, is_vmi = false) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/bki_districts/${provinceCode}.json`);
  let sub_districts = res.data;
  if (is_vmi === true) {
    return sub_districts
      .filter((data) => data.districtcode === districtCode)
      .map((data) => {
        data.key = data.subdistrictcode;
        data.text = data.subdistrictname;
        data.value = data.subdistrictname;
        data.zip = data.zip;
        return data;
      });
  }
  return sub_districts
    .filter((data) => data.districtcode === districtCode)
    .map((data) => {
      data.key = data.subdistrictcode;
      data.text = data.subdistrictname;
      data.value = data.subdistrictcode;
      data.zip = data.zip;
      return data;
    });
};

export const getSubDistrictOptions = async (districtCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/subdistricts/${districtCode}.json`);
  let subDistricts = res.data;

  return subDistricts.map((data) => {
    data.key = data.subdistrictcode;
    data.text = data.subdistrictname;
    data.value = data.subdistrictname;

    return data;
  });
};

export const getVehicleMakes = async (vehicleCategory) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vehicles/${vehicleCategory}/makes.json`);

  return res.data;
};

export const getAsiaVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/asia_vehicles/makes.json`);

  return res.data;
};

export const getBKIVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/bki_vehicles/makes.json`);

  return res.data;
};

export const getSEIVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/sei_vehicles/makes.json`);

  return res.data;
};

export const getAxaVehicleMakes = async (registertype) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/axa_vehicles/makes.json`);
  res.data = res.data.filter((make) => make.registertype === registertype);
  return res.data;
};

export const getAxaModels = async (modelCode, registertype) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/axa_vehicles/models/${modelCode}.json`);
  res.data = res.data.filter((model) => model.registertype === registertype);
  return res.data;
};

export const getAxaColor = async () => {
  // used for tmth
  let res = await axios.get(`${process.env.PUBLIC_URL}/axa_vehicles/color.json`);
  return res.data;
};

export const getIndara2Color = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/indara2_vehicles/color.json`);
  return res.data;
};

export const getBkiColor = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/bki_vehicles/color.json`);
  return res.data;
};

export const getTniColor = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tni_vehicles/color.json`);
  return res.data;
};

export const getTMTHVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tmth_vehicles/makes.json`);
  return res.data;
};

export const getTMTHModel = async (modelcode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tmth_vehicles/model/${modelcode}.json`);
  return res.data;
};

export const getVIBModel = async (modelcode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vib_vehicles/models/${modelcode}.json`);
  return res.data;
};

export const getIndaraBranchModel = async (brandcode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/sei_vehicles/model/${brandcode}.json`);
  return res.data;
};

export const getTSIVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tsi_vehicles/makes.json`);
  return res.data;
};

export const getVIBVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vib_vehicles/makes.json`);
  return res.data;
};

export const getIndaraVehicleMakes = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/indara_vehicles/makes.json`);

  return res.data;
};

export const getAsiaPricingData = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/asia_vehicles/pricedata.json`);
  return res.data;
};

export const getTMTHPricingData = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tmth_vehicles/pricedata.json`);
  return res.data;
};

export const getTSIPricingData = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tsi_vehicles/pricedata.json`);
  return res.data;
};

export const getVIBPricingData = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vib_vehicles/pricedata.json`);
  return res.data;
};

export const getBKIPricingData = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/bki_vehicles/pricedata.json`);
  return res.data;
};

export const getVehicleModels = async (vehicleCategory, makeCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vehicles/${vehicleCategory}/models/${makeCode}.json`);

  return res.data;
};

export const getAsiaVehicleModels = async (makeCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/asia_vehicles/models/${makeCode}.json`);

  return res.data;
};

export const getTSIVehicleModels = async (makeCode) => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/tsi_vehicles/models/${makeCode}.json`);
  return res.data;
};

export const getSEIColor = async () => {
  // used for indara2 (branch) and tmth
  let res = await axios.get(`${process.env.PUBLIC_URL}/sei_vehicles/color.json`);
  return res.data;
};

export const getVIBColor = async () => {
  let res = await axios.get(`${process.env.PUBLIC_URL}/vib_vehicles/color.json`);
  return res.data;
};

export const convertArrayOfObjectsToCSV = (args) => {
  let result, ctr, keys, columnDelimiter, lineDelimiter, data, keyMap;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = args.keys || Object.keys(data[0]);

  keyMap = keys.map((data) => {
    return snakeToTitleCase(data);
  });

  result = '';
  result += keyMap.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function (item) {
    ctr = 0;
    keys.forEach(function (key) {
      if (ctr > 0) result += columnDelimiter;

      result += item[key] || '';
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCsvData = (data) => {
  let csvKeys = Object.keys(data[0]);
  let csvContent = convertArrayOfObjectsToCSV({ data, keys: csvKeys });
  var fileName = 'download';
  //this will remove the blank-spaces from the title and replace it with an underscore
  // fileName += ReportTitle.replace(/ /g,"_");

  //Initialize file format you want csv or xls
  var uri = 'data:text/xls;charset=utf-8,' + '\uFEFF' + csvContent;

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement('a');
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden';
  link.download = fileName + '.xls';

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const objToUrlParams = (obj) => {
  let str = '';
  for (let key in obj) {
    if (str != '') {
      str += '&';
    }
    str += key + '=' + encodeURIComponent(obj[key]);
  }
  return '?' + str;
};

export const getPreviousInsurer = (previousInsurer) => {
  let filterData = getFilterData();
  let insurers = store.getState().insurers;
  let fairdeeInsurers = {};
  Object.keys(insurers).forEach((data) => {
    fairdeeInsurers[insurers[data].name] = data;
  });
  if (
    !previousInsurer &&
    filterData &&
    filterData.current_insurer &&
    currentInsurers.find((insurer) => insurer === filterData.current_insurer)
  ) {
    let ins = currentInsurers.find((insurer) => insurer === filterData.current_insurer);
    return fairdeeInsurers[currentInsurerMap[ins]];
  }
  if (previousInsurer && currentInsurers.find((insurer) => insurer === previousInsurer)) {
    let ins = currentInsurers.find((insurer) => insurer === previousInsurer);
    return fairdeeInsurers[currentInsurerMap[ins]];
  }
  return null;
};

export const notify = (message, type) => {
  store.dispatch(
    showNotification('NOTIFICATION', {
      message: snakeToTitleCase(message),
      type: type,
      autoClose: true,
    })
  );
};

export const errorHandler = (error, notification = false) => {
  const MESSAGES = store.getState().languageMap.messages;
  const languageMap = store.getState().languageMap.fairdeeXplus;

  let errorObj = {};
  if (!error) {
    errorObj = {
      error: languageMap['Something Went Wrong!'],
    };
  }
  if (error && error.status === 500) {
    errorObj = {
      error: languageMap['Something Went Wrong!'],
    };
  }
  if (
    error &&
    error.data &&
    error.data.errors &&
    (error.data.errors.constructor === Object || error.data.errors.constructor === String)
  ) {
    if (error.data.errors.constructor === Object) {
      Object.keys(error.data.errors).forEach((key) => {
        if (Array.isArray(error.data.errors[key])) {
          error.data.errors[key].forEach((data) => {
            errorObj[key] = MESSAGES[data] || data;
          });
        } else if (error.data.errors[key].constructor === Object) {
          Object.keys(error.data.errors[key]).forEach((k) => {
            errorObj[k] = MESSAGES[error.data.errors[key][k]] || error.data.errors[key][k];
          });
        } else if (error.data.errors[key].constructor === String) {
          errorObj[key] = MESSAGES[error.data.errors[key]] || error.data.errors[key];
        }
      });
    } else if (error.data.errors.constructor === String) {
      errorObj['error'] = error.data.errors;
    }
  } else if (
    error &&
    error.data &&
    error.data.response &&
    (error.data.response.constructor === Object || error.data.response.constructor === String)
  ) {
    if (error.data.response.constructor === Object) {
      Object.keys(error.data.response).forEach((key) => {
        if (error.data.response[key].constructor === String) {
          errorObj[key] = MESSAGES[error.data.response[key]] || error.data.response[key];
        } else {
          Object.keys(error.data.response[key]).forEach((k) => {
            errorObj[key] = MESSAGES[error.data.response[key][k]] || error.data.response[key][k];
          });
        }
      });
    } else if (error.data.response.constructor === String) {
      errorObj['error'] = error.data.response;
    }
  } else if (error && error.data && !error.data.errors && error.data.constructor === Object) {
    Object.keys(error.data).forEach((key) => {
      if (Array.isArray(error.data[key])) {
        error.data[key].forEach((data) => {
          errorObj[key] = MESSAGES[data] || data;
        });
      } else if (error.data[key] && error.data[key].constructor === Object && error.data[key].constructor !== null) {
        Object.keys(error.data[key]).forEach((k) => {
          errorObj[k] = MESSAGES[error.data[key][k]] || error.data[key][k];
        });
      } else if (error.data[key]?.constructor === String) {
        errorObj[key] = error.data[key];
      }
    });
  }
  if (error && error.data && error.data.detail) {
    errorObj = {
      ...errorObj,
      message: MESSAGES[error.data.detail] || error.data.detail,
    };
  }
  // if (error && error.data && error.data.detail) {
  //   errorObj = {
  //     ...errorObj,
  //     message: MESSAGES[error.data.detail] || error.data.detail
  //   };
  // }
  if (notification) {
    Object.keys(errorObj).forEach((key, index) => {
      ((i) => {
        setTimeout(() => {
          notify(`${MESSAGES[key] || key} -> ${errorObj[key]}`, 'error');
        }, 1000 + 2000 * i);
      })(index);
    });
  }
  return errorObj;
};

export const notifyList = (obj = {}) => {
  Object.keys(obj).forEach((key, index) => {
    ((i) => {
      setTimeout(() => {
        notify(`${key} -> ${obj[key]}`, 'error');
      }, 1000 + 2000 * i);
    })(index);
  });
};

export const fullScreen = () => {
  setTimeout(function () {
    // Hide the address bar!
    window.scrollTo(0, 1);
  }, 0);
};

export const hideAddressBar = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
};

export const changeUrlParams = (url = undefined, name = undefined, value = undefined) => {
  if (!url || !name) {
    return;
  }
  let params = getUrlparamsAsObject();
  if (!value) {
    delete params[name];
  } else {
    params[name] = value;
  }
  if (Object.keys(params).length) {
    url += objToUrlParams(params);
  }
  window.history.pushState(null, null, url);
};

export const numberWithCommas = (x) => {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

function addListenerEvent(e) {
  googleEvent({
    hitType: 'event',
    eventCategory: 'click',
    eventAction: e.target.innerText,
    eventLabel: e.target.innerText + ' - ' + e.target.baseURI,
  });
}

export const addClickListeners = () => {
  function myFunction(e) {
    if (e.target.tagName === 'BUTTON' && e.target.innerText) {
      addListenerEvent(e);
    }
  }
  window.addEventListener('click', myFunction, false);
};

export const isValidIsoDate = (date) => {
  return moment(date, moment.ISO_8601, true).isValid();
};

export const commaSeperatedNumbers = (num, decimalPlaces = 2) => {
  if (num === '-') {
    return num;
  }

  if (typeof num === 'string' && (num.indexOf('-') > -1 || num.indexOf(',') > -1)) {
    return num;
  }

  if (isNaN(num)) {
    return 0;
  }

  if (!num && num !== '0' && num !== 0) {
    return null;
  }

  return commaNumber(parseFloat(num).toFixed(decimalPlaces));

  // // TODO: Shouldn't the locale be picked from storage rather than hard-coding?
  // return parseFloat(num).toFixed(decimalPlaces).toLocaleString('en');
};

export const isMlmWithNoLicense = (affiliate) => {
  if (!affiliate) {
    return null;
  }
  if (
    isMlmAgent(affiliate) &&
    !affiliate.has_broker_license &&
    (affiliate?.grade?.level_rank === 1 || affiliate?.grade?.level_rank === 2)
  ) {
    return true;
  }
  return false;
};

export const isMember = (affiliate, ignoreAdmin) => {
  let data = affiliate || store.getState().affiliate;
  let user = store.getState().user;
  // Safety check for logged out state
  if (!Object.keys(data).length && Object.keys(user).lenght) {
    return false;
  }

  if (!data.has_broker_license && (!user.roles || !user.roles.includes('admin')) && !ignoreAdmin) {
    return true;
  }
  if (ignoreAdmin && !data.has_broker_license) {
    return true;
  }
  return false;
};

export const isAgent = () => {
  let data = store.getState().affiliate;
  let user = store.getState().user;
  if (data.has_broker_license && (!user.roles || (user.roles.length && !user.roles.includes('admin')))) {
    return true;
  }
  return false;
};

export const isDealerOrLeadgen = () => {
  let data = store.getState().affiliate;
  if (data.bb_account || data.account_type == 'dealership') {
    return true;
  }
  return false;
};

export const isFDAgent = (affiliate = null) => {
  affiliate = affiliate || store.getState().affiliate;
  if (!affiliate.account_type) {
    return true;
  }
  return false;
};

export const isInspectionGarage = (affiliate = null) => {
  affiliate = affiliate || store.getState().affiliate;
  if (affiliate.account_type === 'inspection_garage') {
    return true;
  }
  return false;
};

export const isSubAgent = (affiliate = null) => {
  affiliate = affiliate || store.getState().affiliate;
  if (affiliate.account_type === 'subagent') {
    return true;
  }
  return false;
};

export const isAOAgent = (affiliate = null) => {
  affiliate = affiliate || store.getState().affiliate;
  if (affiliate.account_type === 'ao_agent') {
    return true;
  }
  return false;
};

export const isMlmAgent = (affiliate = null) => {
  affiliate = affiliate || store.getState().affiliate;
  if (affiliate.account_type === 'mlm_agent') {
    return true;
  }
  return false;
};

export const isDirectAgent = (affiliate = null) => {
  affiliate = affiliate || store.getState().affiliate;
  if (!isMember(affiliate) && affiliate && !affiliate.account_type) {
    return true;
  }
  return false;
};

export const isDealership = () => {
  let data = store.getState().affiliate;
  if (data.account_type === 'dealership') {
    return true;
  }
  return false;
};

export const isTelesales = () => {
  let data = store.getState().affiliate;
  if (data.account_type === 'telesales') {
    return true;
  }
  return false;
};

export const isBusinessManagerAgent = () => {
  let data = store.getState().affiliate;
  if (data.account_type === 'business_manager') {
    return true;
  }
  return false;
};

export const isAdmin = () => {
  let user = store.getState().user;
  if (user.roles && user.roles.includes('admin')) {
    return true;
  }
  return false;
};

export const isSuperAdmin = () => {
  let user = store.getState().user;
  if (user.roles && user.roles.includes('super_admin')) {
    return true;
  }
  return false;
};

export const isAuthorizedUser = () => {
  let user = store.getState().user;
  if (user.has_oic_and_installment_access) {
    return true;
  }
  return false;
};

export const getCurrentAffiliate = () => {
  return store.getState().affiliate;
};

export const scrollInnerElements = (parentElement, scrollToElement, offset = 0) => {
  if (!parentElement || !scrollToElement) {
    return;
  }
  let elementTop = scrollToElement.offsetTop,
    topPosition = scrollToElement.parentElement.parentElement.offsetTop + elementTop - offset;
  parentElement.scrollTop = topPosition;
};
function listenerAction(params) {
  googleEvent({
    hitType: 'event',
    eventCategory: params.type,
    eventAction: params.name,
    eventLabel: params.name + ' - ' + params.baseURI,
  });
}

export const addListeners = ({ target }) => {
  let type = target.getAttribute('e-type'),
    name = target.getAttribute('e-name'),
    baseURI = target.baseURI;

  listenerAction({
    type,
    name,
    baseURI,
  });
};

export const registerEventsTracking = (element) => {
  if (!element) {
    return;
  }
  const eventTypes = {
    click: true,
  };
  let elements = element.querySelectorAll('[e-tracker]');
  for (let i = 0; i < elements.length; i++) {
    let type = elements[i].getAttribute('e-type');
    type = eventTypes[type] || 'click';
    elements[i].addEventListener(type, addListeners, false);
  }
};

export const unRegisterEventsTracking = (element) => {
  if (!element) {
    return;
  }
  const eventTypes = {
    click: true,
  };
  let elements = element.querySelectorAll('[e-tracker]');
  for (let i = 0; i < elements.length; i++) {
    let type = elements[i].getAttribute('e-type');
    type = eventTypes[type] || 'click';
    elements[i].removeEventListener(type, addListeners, false);
  }
};

export const dateDotFormat = (date) => {
  if (!date) {
    return;
  }
  date = new Date(date);
  let day = date.getDate(),
    month = date.getMonth() + 1,
    year = date.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  return `${day}.${month}.${year}`;
};

export const docReady = (callback) => {
  // see if DOM is already available
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // call on next available tick
    setTimeout(callback, 1);
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

export const getTransferableRate = (quotation) => {
  if (WHT_EXEMPTED_AGENTS.includes(quotation.affiliate?.account_type)) {
    return 1;
  }

  let transferableRate = 0.97;
  if (quotation.wht_rate) {
    transferableRate = 1 - quotation.wht_rate;
  }
  if (quotation.affiliate && quotation.affiliate.vat_enabled) {
    transferableRate += 0.07;
  }
  return transferableRate;
};

export const findTotalPremium = (quoteOrSale) => {
  let quotation = quoteOrSale;

  if (!quoteOrSale) {
    return 0;
  }

  if (quoteOrSale.quotation) {
    quotation = quoteOrSale.quotation;
  }

  if (!quotation.price_list) {
    return 0;
  }

  let premium = quotation.price_list.premium_after_tax;
  if (quotation.needs_compulsory_insurance) {
    premium += quotation.cmi_price_list.premium_after_tax;
  }
  return premium;
};

export const isQuotationPaid = (quotation) => {
  const { thai_qr_request, sale } = quotation;
  if (
    (thai_qr_request && thai_qr_request.is_paid) ||
    (sale && ALL_ENCOMPASSING_PAID_STATUSES.includes(sale.payment_status))
  ) {
    return true;
  }

  return false;
};

export const updatePageNumber = (url, page) => {
  let params = getUrlparamsAsObject();
  params.page = page;
  url += objToUrlParams(params);
  window.history.pushState(null, null, url);
};

export const localCopy = (a) => {
  return JSON.parse(JSON.stringify(a));
};

export const getCommissionFromTotalPremium = (premium, quotation, isCompulsory) => {
  let commissionRate = 0;
  let whtRate = 0;
  /*
   Subject to change. Calculated with duty=0.004 & tax 0.07
  */
  const premiumMinusTax = parseFloat(parseFloat(premium / 1.07428).toFixed(2));

  if (quotation) {
    if (!isCompulsory) {
      commissionRate = quotation.commission_rate;
    } else {
      commissionRate = quotation.compulsory_commission_rate;
    }
    whtRate = quotation.wht_rate;
  }
  const calculatedCommission = premiumMinusTax * commissionRate;
  return parseFloat(calculatedCommission - calculatedCommission * whtRate).toFixed(2);
};

export const getApplicableIncentivePercent = ({ current = 0, previous = 0 }) => {
  let currentIncentive = 0,
    nextIncentive = 0,
    remainingAmount = 0;

  if (!isAgent()) {
    return { currentIncentive: 0, totalIncentive: 0, remainingAmount: 0 };
  }

  if (current < 10000) {
    remainingAmount = 10000 - current;
    currentIncentive = 0;
    nextIncentive = 150;
  } else {
    let currentMultiplier = Math.floor(current / 10000),
      nextMultiplier = currentMultiplier + 1,
      currentAdditionalRate = 0,
      nextAdditionalRate = 0;

    if (currentMultiplier >= 30) {
      currentAdditionalRate = 0.015;
    } else if (currentMultiplier >= 20) {
      currentAdditionalRate = 0.01;
    } else if (currentMultiplier >= 10) {
      currentAdditionalRate = 0.0075;
    } else if (currentMultiplier >= 8) {
      currentAdditionalRate = 0.005;
    } else if (currentMultiplier >= 5) {
      currentAdditionalRate = 0.0025;
    }

    if (nextMultiplier >= 30) {
      nextAdditionalRate = 0.015;
    } else if (nextMultiplier >= 20) {
      nextAdditionalRate = 0.01;
    } else if (nextMultiplier >= 10) {
      nextAdditionalRate = 0.0075;
    } else if (nextMultiplier >= 8) {
      nextAdditionalRate = 0.005;
    } else if (nextMultiplier >= 5) {
      nextAdditionalRate = 0.0025;
    }

    remainingAmount = nextMultiplier * 10000 - current;
    currentIncentive = currentMultiplier * 150 + currentMultiplier * 10000 * currentAdditionalRate;
    nextIncentive = nextMultiplier * 150 + nextMultiplier * 10000 * nextAdditionalRate;
  }

  return {
    currentIncentive: numberWithCommas(commaSeperatedNumbers(currentIncentive, 0)),
    remainingAmount: numberWithCommas(commaSeperatedNumbers(remainingAmount, 0)),
    nextIncentive: numberWithCommas(commaSeperatedNumbers(nextIncentive, 0)),
  };
};

export const detectPlatform = () => {
  let standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent),
    view = null;

  if (ios) {
    if (!standalone && safari) {
      // Safari
      view = 'safari';
    } else if (!standalone && !safari) {
      // iOS webview
      view = 'ios-wv';
    }
  } else {
    if (userAgent.includes('wv')) {
      view = 'android-wv';
    } else {
      view = 'chrome';
    }
  }
  return view;
};

export const checkInstalmentAllowed = (params, qq = null) => {
  if (qq && qq.will_pay_in_instalments) {
    return true;
  }

  return Boolean(params.insurer.is_instalment_supported && !params.disable_instalment);
};

export const toDataUrl = async (url, callback) => {
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    let reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
};

export const downloadDocument = (url) => {
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const iosPdf = (url, name = 'Quotation') => {
  let link = document.createElement('a');
  link.textContent = 'download';
  link.href = `${url}`;
  link.setAttribute('download', name);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  document.body.removeChild(link);

  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.qoala) {
    window.webkit.messageHandlers.qoala.postMessage({
      action: 'openPdf',
      content: url,
    });
  }
};

export const downloadAdditionalDocument = (url, name = 'download', ext = 'pdf') => {
  const iOS = detectPlatform() === 'ios-wv';
  if (iOS) {
    iosPdf(url, name);
  } else {
    let extTemp = ext,
      fileNameArray = name
        ?.trim()
        .split('.')
        .filter((text) => text === ext);

    if (fileNameArray.length > 1) {
      extTemp = fileNameArray.pop();
    }
    downloadDocs(url, name, extTemp);
  }
};

export const toFixedDecimal = (n) => {
  n = toFixedNumber(n);
  return parseFloat(n);
};

export const getLineUrl = () => {
  if (isAgent() && !isMlmAgent()) {
    return FAIRDEE_LINE_URL;
  }
  return FAIRDEE_MLM_LINE_URL;
};

export const getLineId = () => {
  if (isAgent() && !isMlmAgent()) {
    return FAIRDEE_LINE_ID;
  }
  return FAIRDEE_MLM_LINE_ID;
};

export const downloadPdfDocument = (data = {}) => {
  const doc = new jsPDF(),
    element = document.createElement('div');
  element.setAttribute('id', 'adderess-card');
  element.innerHTML = `
        <div class="aff-address-card">
          <p>${data.contact_name}</p>
          <p>${data.first_line}&nbsp; ${data.subdistrict}&nbsp; ${data.district}</p>
          <p>${data.province}&nbsp; ${data.postal_code}&nbsp; ${
    data.contact_number ? ` - ${data.contact_number}` : ''
  }</p>
          <p>${data.vehicle_number}</p>
        </div>`;
  document.body.appendChild(element);

  let node = document.getElementById('adderess-card');

  document.body.appendChild(element);
  doc.html(node, {
    callback: function (doc) {
      doc.save(data.contact_name + '-address');
      node.remove();
    },
  });
};

export const replaceConsecutiveCharactedWithAestric = (text = '') => {
  let words = text.split(' '),
    formattedString = '';
  words.map((word, _index) => {
    for (let i = 0; i < word.length; ++i) {
      if (i % 2 == 1) {
        word = word.substring(0, i) + '*' + word.substring(i + 1);
      }
    }
    word += ' ';
    formattedString += word;
  });
  return formattedString;
};

export const getDeviceType = () => {
  try {
    if (mobileClient) {
      return 'app';
    }
  } catch (e) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return 'm-web';
    } else {
      return 'web';
    }
  }
};

export const getAffiliateAttributes = () => {
  let affiliate = store.getState().affiliate,
    obj = {
      is_admin: isAdmin() || isSuperAdmin(),
    };
  if (affiliate?.agent_code) {
    obj.agent_code = affiliate?.agent_code;
  }
  if (affiliate?.account_type) {
    obj.account_type = affiliate?.account_type;
  }

  return obj;
};

export const validateAddress = (data) => {
  let isComplete = true,
    requiredFields = ['first_line', 'province', 'district', 'subdistrict', 'postal_code'];

  requiredFields.forEach((field) => {
    if (data[field] === null || data[field] === '' || data[field] === undefined) {
      isComplete = false;
    }
  });

  return isComplete;
};

export const validatePersonalDetails = (data) => {
  let isComplete = true,
    requiredFields = ['phone', 'fullname', 'email', 'has_broker_license', 'communication_slot_preference'];

  requiredFields.forEach((field) => {
    if (data[field] === null || data[field] === '' || data[field] === undefined) {
      isComplete = false;
    }
  });

  return isComplete;
};

export const validateBrokerLicense = (data) => {
  let isComplete = true,
    requiredFields = ['national_id', 'broker_license_number', 'broker_license_expiry_date'];

  requiredFields.forEach((field) => {
    if (data[field] === null || data[field] === '' || data[field] === undefined) {
      isComplete = false;
    }
  });

  return isComplete;
};

export const validatebankAccount = (data) => {
  let isComplete = true,
    requiredFields = ['bank_name', 'ac_number', 'ac_holder_name', 'branch_code'];

  requiredFields.forEach((field) => {
    if (data[field] === null || data[field] === '' || data[field] === undefined) {
      isComplete = false;
    }
  });

  return isComplete;
};

export const validateAgentProfile = (data) => {
  let isComplete = true,
    requiredFields = [
      'dob',
      'gender',
      'type_of_lead',
      'occupation',
      'years_of_experience',
      'selling_for_other_insurers',
    ];

  requiredFields.forEach((field) => {
    if (data[field] === null || data[field] === '' || data[field] === undefined) {
      isComplete = false;
    }
  });

  return isComplete;
};

export const isProfileComplete = (data) => {
  if (
    validateAddress(data) &&
    validatePersonalDetails(data) &&
    validateBrokerLicense(data) &&
    validatebankAccount(data) &&
    validateAgentProfile(data)
  ) {
    return true;
  }
  return false;
};

export const isEmailOtp = () => {
  return store.getState().systemSettings.is_sms_vendor_down;
};

export const formatMobile = (mobile) => {
  return `+66 ${mobile.substring(0, 1)} ${mobile.substring(1, 5)} ${mobile.substring(5, 9)}`;
};

export const validatePassword = (password) => {
  if (!password || password.length < 8) {
    return false;
  }
  return true;
};

export const handlecontactOps = (msg) => {
  if (msg === 'กรุณาติดต่อเจ้าหน้าที่ทางไลน์แอดแฟร์ดี หรือ ติดต่อที่เบอร์ 02-114-7920 ต่อ 2') notify(msg);
};

export const formatEmail = (username) => {
  let user_name = '';
  if (username.indexOf('***') > -1) {
    user_name = username;
  } else {
    let temparray = username.split('@');

    if (temparray[0].length > 4) {
      user_name = `${temparray[0].substring(0, 2)}***${temparray[0].substring(temparray[0].length - 2)}${temparray[1]}`;
    } else {
      user_name = `${temparray[0].substring(0, 2)}***${temparray[1]}`;
    }
  }
  return user_name;
};

export const validateBKIVehicleNumber = (vehicle_number = '') => {
  if (!vehicle_number) {
    return 'required';
  }

  let validationResult = validateVehicleNumber(vehicle_number);
  if (validationResult !== true) {
    return validationResult;
  }

  const regex = /^[0-9]{0,1}([\u0E00-\u0E7F]){0,2}[0-9]{1,4}$/;
  if (!regex.test(vehicle_number)) {
    return 'หมายเลขทะเบียนรถควรประกอบด้วยตัวอักษรภาษาไทยและตัวเลขเท่านั้น';
  }
  return true;
};

export const validateCorporateId = (corporate_id = '') => {
  if (corporate_id && corporate_id.length == 13) {
    return true;
  }
  return false;
};

export const isPartOfMLMNetwork = (affiliate) => {
  if (
    !affiliate ||
    affiliate.account_type !== 'mlm_agent' ||
    !affiliate.mlm_node ||
    !affiliate.mlm_node.leader ||
    (affiliate.id === affiliate.mlm_node.leader.id && affiliate.grade.level_rank < 3)
  ) {
    return false;
  }

  return true;
};

export const canRecordEndorsement = (quotation) => {
  if (!quotation) {
    return false;
  }
  const sale = quotation.sale,
    quotationQuery = quotation.quotation_query;

  return (
    isAdmin() &&
    sale &&
    sale.sale_type !== 'credit' &&
    quotation.payment_method !== 'credit' &&
    ALL_ENCOMPASSING_PAYMENT_STATUSES.includes(sale.payment_status) &&
    sale.policy_status !== 'docs_rejected'
  );
};

export const getPaymentAmount = (quotation, premiumInvoices) => {
  const details = { paidAmount: 0, reconciledAmount: 0 };

  if (!quotation || !premiumInvoices || !premiumInvoices.length) {
    return details;
  }

  details.paidAmount = premiumInvoices.reduce(
    (retVal, inv) =>
      parseFloat(retVal) +
      parseFloat(
        inv.payments.reduce((paymentVal, payment) => parseFloat(paymentVal) + parseFloat(payment.amount_paid), 0)
      ),
    0
  );
  details.reconciledAmount = premiumInvoices.reduce(
    (retVal, inv) =>
      parseFloat(retVal) +
      parseFloat(
        inv.payments.reduce((paymentVal, payment) => {
          if (payment.is_reconciled) {
            return parseFloat(paymentVal) + parseFloat(payment.amount_paid);
          }
          return paymentVal;
        }, 0)
      ),
    0
  );

  return details;
};

export const isIgWithInstantPolicy = (quotation) => {
  return Boolean(
    quotation.affiliate.account_type === 'inspection_garage' && quotation.price_list.can_issue_policy_online
  );
};

export const inRange = (x, min, max) => {
  return (x - min) * (x - max) <= 0;
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const redirectToNew = (affiliate = null) => {
  const data = affiliate || store.getState().affiliate || {};
  const urlParams = getUrlparamsAsObject();
  let shouldRedirect = true;

  if (urlParams.ig || urlParams.business_manager || urlParams.ref) {
    shouldRedirect = false;
  }
  // Safety check for infinite loop: to break away from constant looping
  if (!window.location.pathname.includes('/logout') && shouldRedirect) {
    /*
     * If a MLM or FD agent lands on old portal, redirect them to new portal.
     * Old portal remains for IG, Subagent, Non MLM member and admins
     */
    if (data.id && !isAdmin(affiliate) && (isMlmAgent(affiliate) || isDirectAgent(affiliate))) {
      // Do no redirect inside app
      if (!['ios-wv', 'android-wv'].includes(detectPlatform()) && getDeviceType() !== 'app') {
        let redirectPath = `${NEW_PORTAL}`,
          search = window.location.search,
          pathname = window.location.pathname; // ?intent=internal_redirect
        if (pathname === '/fairdee-wizard') {
          redirectPath += pathname;
        }
        redirectPath += '?intent=internal_redirect';
        if (search) {
          redirectPath = `${redirectPath}&${search.replace('?', '')}`;
        }
        // Replace URL to make sure history is overridden as well
        window.location.replace(redirectPath);
        // Hacky way to ensure no javascript is executed once the redirection starts
        throw new Error('Breaking for redirection. Nevermind');
      }
      //Handling for mobile app can go here
    }
  }
};

// Convers cookie into key value pair Object
export const getCookieJar = () => {
  return document.cookie
    .split(';')
    .map((pair) => pair.split('='))
    .filter((pair) => pair.length === 2)
    .reduce((retVal, pair) => ({ ...retVal, [pair[0].trim()]: pair[1].trim() }), {});
};

// To be used for internal redirection only: in case internally redirected, pick cookie token and set in localstorage
export const updateTokensFromCookie = () => {
  const cookieJar = getCookieJar();
  if (cookieJar.access_token) {
    setAccessToken(cookieJar.access_token);
  }
  if (cookieJar.refresh_token) {
    setRefreshToken(cookieJar.refresh_token);
  }
};

export const openFile = (url) => {
  const link = document.createElement('a');
  link.setAttribute('download', true);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const isEmptyDict = (dict) => {
  return Object.keys(dict).length === 0;
};
export const handleElementScroll = (el) => {
  if (el.target.scrollHeight - el.target.scrollTop - el.target.clientHeight < 1) {
    return true;
  }
};

export const downloadDocs = (url, fileName, fileExt) => {
  let urlWithExt = '';
  if (url.includes('resp_format')) {
    const getExt = url.split('&').filter((item) => {
      if (item.includes('resp_format')) {
        return item;
      }
    });
    const extParams = `&ext=.${getExt[0].split('=')[1]}`;
    urlWithExt = `${url}${extParams}`;
  } else {
    if (!fileExt) {
      urlWithExt = `${url}?ext=${url.slice(url.lastIndexOf('.'))}`;
    } else {
      urlWithExt = `${url}${url.includes('?') ? '&' : '?'}ext=.${fileExt}`;
    }
  }

  let link = document.createElement('a');
  link.textContent = 'download';
  link.href = urlWithExt;
  link.setAttribute('download', 'file');
  link.style.visibility = 'hidden';
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getImageDetails = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ height: img.height, width: img.width });
    img.onerror = reject;
    img.src = src;
  });
};

export const getImageFromFile = (file) => {
  return new Promise((resolve, reject) => {
    let fr = new FileReader();
    fr.onload = () => resolve(getImageDetails(fr.result));
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
};

export const disableInputNumberWheelEvent = () => {
  let inputTypeNumbers = document.querySelectorAll('input[type=number]');
  for (let a = 0; a < inputTypeNumbers.length; a++) {
    inputTypeNumbers[a].onwheel = function (event) {
      event.target.blur();
    };
  }
};

export const isScAssigned = (quotationQuery) => {
  if (quotationQuery.assign_to?.id) {
    return true;
  }
  return false;
};

export const hasPrepaidCredits = (affiliate = store.getState().affiliate) => {
  if (!affiliate.credit || !affiliate.credit.length) {
    return true;
  }
  return affiliate.credit.some((credit) => credit.is_prepaid);
};

export const isPrintingPreferenceSelectionBlocked = (quotation) => {
  const now = moment(),
    printingBlockedAfter = moment(quotation.created_at).add(1, 'days').startOf('day');
  return !isAdmin() && now.isAfter(printingBlockedAfter);
};

export const needsRedirection = (affiliate) => {
  if (isAdmin(affiliate) || isSuperAdmin(affiliate)) {
    return false;
  }
  if (affiliate.id && affiliate.block_new_portal === true && (isInspectionGarage(affiliate) || isSubAgent(affiliate))) {
    return false;
  }
  if ((affiliate.id && isMlmAgent(affiliate)) || isDirectAgent(affiliate)) {
    return true;
  }
  if (affiliate.id && !isAdmin(affiliate) && !isSuperAdmin(affiliate) && affiliate.block_new_portal === false) {
    return true;
  }
  return false;
};

export const includeDateCut = (url = '', params = {}, flow = 'quotationReport') => {
  if (getParameterByName('type') && getParameterByName('type') === 'renewals') {
    if (!url.includes('policy_end_date_after') && !params['policy_end_date_after']) {
      params.policy_end_date_after = moment().subtract(3, 'months').format('YYYY-MM-DD');
      params.policy_end_date_before = moment().add(1, 'days').format('YYYY-MM-DD');
    }

    return params;
  }

  if (
    (flow === 'quotationReport' || flow === 'leadsReport') &&
    !url.includes('created_at__lte') &&
    !params['created_at__lte']
  ) {
    params.created_at__lte = moment()
      .add(1, 'days')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString();
    params.created_at__gte = moment()
      .subtract(1, 'months')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString();
    return params;
  }
};

export const isBlockedInstalmentTypeSelected = (instalmentType, blockedInstalmentTypes) => {
  let blockedInstalmentTypeSelected = false;
  blockedInstalmentTypes.map((blockedInstalmentType) => {
    if (
      instalmentType.name === blockedInstalmentType.name &&
      instalmentType.instalment_plan === blockedInstalmentType.instalment_plan
    ) {
      blockedInstalmentTypeSelected = true;
    }
  });
  return blockedInstalmentTypeSelected;
};

export const appendBlockedInstalmentTypesIfSelected = (
  allInstalmentTypes,
  selectedInstalmentType = {},
  total_premium
) => {
  const systemSettings = store.getState().systemSettings,
    blockedInstalmentTypes = systemSettings?.blocked_instalment_types || [];

  if (Object.keys(selectedInstalmentType).length) {
    if (isBlockedInstalmentTypeSelected(selectedInstalmentType, blockedInstalmentTypes)) {
      selectedInstalmentType['equal_instalment_breakup_value'] = total_premium / selectedInstalmentType.breakup.length;
      allInstalmentTypes.push(selectedInstalmentType);
    }
  }
  return allInstalmentTypes;
};

export const formatDoubleCurlyBracket = (str, result) => {
  return str?.replace(/{{(.+?)}}/g, (_, g1) => result[g1] || g1);
};

export const isEmpty = (value) => {
  // Check if value is null or undefined
  if (value == null) {
    return true;
  }

  // Check if value is an empty string
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  // Check if value is an empty array or object
  if (Array.isArray(value) || typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  // Otherwise, consider the value as not empty
  return false;
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      // Optionally extract the Base64 part after the comma
      const base64Data = base64String.split(',')[1];
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const nextPaymentScheduleCheck = (agentType, level) => {
  if (agentType === null || agentType === 'direct_agent' || agentType === 'ao_agent') {
    return true;
  } else if (agentType === 'mlm_agent' && level >= 3) {
    return true;
  }
  return false;
};

export const getCmiCodeIfRequired = (vmiVehicleCategory, defaultVmiVehicleCategory) => {
  if (vmiVehicleCategory !== defaultVmiVehicleCategory && vmiVehicleCategory in VEHICLE_CATEGORY_VMI_TO_CMI_MAPPING) {
    return VEHICLE_CATEGORY_VMI_TO_CMI_MAPPING[vmiVehicleCategory];
  }
  return '';
};
